<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
            <!-- <div>
                <p>资质事业群</p>
                <span class="english">正规绿色通道，随时随地查询办理进度</span>
                <button @click="gotoAboutus">了解更多</button>
            </div> -->
        </div>
        <div class="kuaijiQ">
            <div>
                <p>资质事业群</p>
                <div
                    style="margin-bottom: 40px;width: 81px;height: 4px;background: linear-gradient( 270deg, #F4F4F4 0%, #EA5514 100%);border-radius: 1px;">
                </div>
                <p class="kuaijiQC">
                    联正企服是唐诚控股旗下专注建筑资质服务的咨询平台，为全国建筑行业企业提供专业高效，省时省心建筑资质咨询服务。
                </p>
                <p class="kuaijiQC">
                    百余位社会专家资源，精准施策申报难点，助力行业企业资质高效申办。狠抓服务，严控客户成本，信息化、流程化推进各个关键的节点和细节，做到事前预判、事中监管、事后落责。
                </p>
            </div>
            <img src="../assets/image/jianzhufuwu.png">
        </div>
        <div class="canIdo">
            <p><img src="../assets/image/left.png">&nbsp;我们可以为您的企业做什么&nbsp;<img src="../assets/image/right.png"></p>
            <span>WHAT CAN WE DO FOR YOUR BUSINESS</span>
            <div>
                <ul>
                    <li>
                        <p>精准施策</p>
                        <span>询问客户需求，解决申报资质难题</span>
                    </li>
                    <li>
                        <p>售后跟踪</p>
                        <span>资质代办流程更加简便后续的服务中为您做到更好</span>
                    </li>
                </ul>
                <img src="../assets/image/kuaiji/canIdo.png">
                <ul>
                    <li>
                        <p>提速办理</p>
                        <span>多人团队，效率高，办理周期短</span>
                    </li>
                    <li>
                        <p>一站式代办</p>
                        <span>复杂流程我们跑，您坐等领证</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="service">
            <p><img src="../assets/image/left.png">&nbsp;服务内容&nbsp;<img src="../assets/image/right.png"></p>
            <span>SERVICE CONTENT</span>
            <ul>
                <li>
                    <div></div>
                    <p class="qizhi">01</p>
                    <p>施工总承包</p>
                    <span>新办/升级/转让，无忧服务</span>
                </li>
                <li>
                    <div></div>
                    <p class="qizhi">02</p>
                    <!-- <img src="../assets/image/kuaiji/biangengO.png"> -->
                    <p>施工专业承包</p>
                    <span>零失误，性价比高</span>
                </li>
                <li>
                    <div></div>
                    <p class="qizhi">03</p>
                    <!-- <img src="../assets/image/kuaiji/yichangO.png"> -->
                    <p>劳务资质</p>
                    <span>极致服务体验，放心托付</span>
                </li>
                <li>
                    <div></div>
                    <p class="qizhi">04</p>
                    <!-- <img src="../assets/image/kuaiji/jizhangO.png"> -->
                    <p>安全生产许可证</p>
                    <span>VIP通道办理</span>
                </li>
                <li>
                    <div></div>
                    <p class="qizhi">05</p>
                    <!-- <img src="../assets/image/kuaiji/shenjiO.png"> -->
                    <p>承装（修、试）电力设施许可证</p>
                    <span>流程标准化</span>
                </li>
                <li>
                    <div></div>
                    <p class="qizhi">06</p>
                    <!-- <img src="../assets/image/kuaiji/shougouO.png"> -->
                    <p>房地产开发资质</p>
                    <span>百家成功案例，值得信赖</span>
                </li>
            </ul>
        </div>
        <div class="flow">
            <p><img src="../assets/image/left.png">&nbsp;服务流程&nbsp;<img src="../assets/image/right.png"></p>
            <span>SERVICE FLOW</span>
            <ul>
                <li>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->

                    <span>洽谈合作</span>
                    <p>01.</p>
                </li>
                <li>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <p>02.</p>
                    <span>签订合同</span>
                </li>
                <li>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->

                    <span>项目立项</span>
                    <p>03.</p>
                </li>
                <li>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <p>04.</p>
                    <span>审查复核</span>
                </li>
                <li>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->

                    <span>组卷申报</span>
                    <p>05.</p>
                </li>
                <li>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <p>06.</p>
                    <span>审批跟踪</span>

                </li>
                <li>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->

                    <span>核准取证</span>
                    <p>07.</p>
                </li>
            </ul>
        </div>
        <div class="personal">
            <div style="display: flex;justify-content: center;"><button @click="infoShow = true">点击咨询</button></div>
            <p><img src="../assets/image/left.png">&nbsp;资深专家&nbsp;<img src="../assets/image/right.png"></p>
            <span>SENIOR EXPERTS</span>
            <ul>
                <li v-for="item in personalList" :key="item.personnelId">
                    <img :src="imgurl + item.personnelImg" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">{{ item.personnelName }}</span>
                        <span class="personalC">{{ item.personnelPosition }}</span>
                    </p>
                </li>
                <!-- <li>
                    <img src="../assets/image/zizhi/personal4.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">杜少鹏</span>
                        <span class="personalC">唐诚资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/zizhi/personal2.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">代振坤</span>
                        <span class="personalC">唐诚石家庄资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/zizhi/personal3.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">沈杰</span>
                        <span class="personalC">唐诚北京资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/zizhi/personal1.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">张娟竺</span>
                        <span class="personalC">唐诚广州资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/zizhi/personal6.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">郑姣姣</span>
                        <span class="personalC">唐诚天津资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/zizhi/personal5.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">孔候平</span>
                        <span class="personalC">唐诚太远资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/zizhi/personal8.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">东倩</span>
                        <span class="personalC">唐诚廊坊资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/zizhi/personal7.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">赵晓东</span>
                        <span class="personalC">唐诚石家庄资质事业部高级顾问</span>
                    </p>
                </li> -->
            </ul>
        </div>

        <div class="advantages">
            <p><img src="../assets/image/left.png">&nbsp;服务优势&nbsp;<img src="../assets/image/right.png"></p>
            <span>SERVICE ADVANTAGES</span>
            <div>
                <img src="../assets/image/zizhi/heshou.png">
                <ul>
                    <li>
                        <div>01</div>
                        <p>精准答疑，破解难题</p>
                    </li>
                    <li>
                        <div>02</div>
                        <p>匠心服务</p>
                    </li>
                    <li>
                        <div>03</div>
                        <p>流程规范，价格透明</p>
                    </li>
                    <li>
                        <div>04</div>
                        <p>拥有自主研发数据平台</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="orange">
            <img src="../assets/image/kuaiji/whiteLine.png">&nbsp;&nbsp;严格规范的资质新办流程&nbsp;·&nbsp;通过率高&nbsp;&nbsp;<img
                src="../assets/image/kuaiji/whiteLine.png">
            <span>资质转让&nbsp;·&nbsp;无债权债务法务风险</span>
        </div>
        <div class="success">
            <p><img src="../assets/image/left.png">&nbsp;成功案例&nbsp;<img src="../assets/image/right.png"></p>
            <span>SUCCESS STORIES</span>
            <div>
                <ul>
                    <li v-for="item in caseList1" :key="item.caseId" @mouseenter="changeContent(item)" @mouseleave="recoveryContent(item)">
                        <img :src="imgurl + item.caseImg">
                        <div>
                            <p>项目：{{ item.caseTitle }}</p>
                            <button>耗时：{{ item.caseHs }}</button>
                            <span v-html="item.caseTitle2"></span>
                            <div class="gengduo"></div>
                        </div>
                    </li>
                    <!-- <li>
                        <img src="../assets/image/zizhi/960a4d9cd29ff705cdd380a3398bfc1a(2).png">
                        <div>
                            <p>项目：财务外包</p>
                            <button>耗时：10天</button>
                            <span>在政策不明朗、业务停滞的困境中，客户面临着巨大的压力和挑战。他感到非常焦虑，直到他听说了唐诚的优质服务和专业团队。便决定尝试一下，希望能为公司找到新的突破口。

                                资质顾问在详细了解了客户的公司情况后，提出了一个全面的解决方案。建议在原有业务的基础上，增加四项资质：建筑装修、机电总包、石油化工和建筑总包。这些建议不仅符合市场需求，还能有效提升公司的竞争力。经过几次深入沟通，客户被唐诚的专业性和真诚所打动，最终决定签约。</span>

                        </div>
                    </li> -->

                </ul>
                <ul>
                    <li v-for="item in caseList2" :key="item.caseId" @mouseenter="changeContent(item)" @mouseleave="recoveryContent(item)">
                        <img :src="imgurl + item.caseImg">
                        <div>
                            <p>项目：{{ item.caseTitle }}</p>
                            <button>耗时：{{ item.caseHs }}</button>
                            <span v-html="item.caseTitle2"></span>
                            <div class="gengduo"></div>
                        </div>
                    </li>
                    <!-- <li>
                        <img src="../assets/image/zizhi/960a4d9cd29ff705cdd380a3398bfc1a(3).png">
                        <div>
                            <p>项目：财务外包</p>
                            <button>耗时：10天</button>
                            <span>在政策不明朗、业务停滞的困境中，客户面临着巨大的压力和挑战。他感到非常焦虑，直到他听说了唐诚的优质服务和专业团队。便决定尝试一下，希望能为公司找到新的突破口。

                                资质顾问在详细了解了客户的公司情况后，提出了一个全面的解决方案。建议在原有业务的基础上，增加四项资质：建筑装修、机电总包、石油化工和建筑总包。这些建议不仅符合市场需求，还能有效提升公司的竞争力。经过几次深入沟通，客户被唐诚的专业性和真诚所打动，最终决定签约。</span>

                        </div>
                    </li>
                    <li>
                        <img src="../assets/image/zizhi/960a4d9cd29ff705cdd380a3398bfc1a.png">
                        <div>
                            <p>项目：财务外包</p>
                            <button>耗时：10天</button>
                            <span>在政策不明朗、业务停滞的困境中，客户面临着巨大的压力和挑战。他感到非常焦虑，直到他听说了唐诚的优质服务和专业团队。便决定尝试一下，希望能为公司找到新的突破口。

                                资质顾问在详细了解了客户的公司情况后，提出了一个全面的解决方案。建议在原有业务的基础上，增加四项资质：建筑装修、机电总包、石油化工和建筑总包。这些建议不仅符合市场需求，还能有效提升公司的竞争力。经过几次深入沟通，客户被唐诚的专业性和真诚所打动，最终决定签约。</span>

                        </div>
                    </li> -->
                </ul>
            </div>

        </div>
        <div class="offer">
            <p><img src="../assets/image/left.png">&nbsp;我们还提供这些服务&nbsp;<img src="../assets/image/right.png"></p>
            <span>WE ALSO OFFER THESE SERVICES</span>
            <ul>
                <li>
                    <p>
                        <img src="../assets/image/kuaiji/caishui.png">
                        <span>财税服务</span>
                    </p>
                    <button @click="gotoKuaiji">了解更多</button>
                </li>
                <li>
                    <p>
                        <img src="../assets/image/kuaiji/qianbao.png">
                        <span>金融服务</span>
                    </p>
                    <button @click="gotoQidai">了解更多</button>
                </li>
                <li>
                    <p>
                        <img src="../assets/image/kuaiji/haiwai.png">
                        <span>海外业务</span>
                    </p>
                    <button @click="gotoHaiwai">了解更多</button>
                </li>
                <li>
                    <p>
                        <img src="../assets/image/kuaiji/zhishi.png">
                        <span>知识产权</span>
                    </p>
                    <button @click="gotoZonghe">了解更多</button>
                </li>
            </ul>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>
        <!-- <div v-if="infoShow"
            style="padding-top: 200px;width:100vw;height: 100vh;background-color:rgba(0, 0, 0, 0.4);position: fixed;top: 0">
            <div class="info">
                <p>扫码关注微信公众号</p>
                <img src="../assets/icon/cheng.png" class="cheng" @click="infoShow = false">
                <img src="../assets/image/name.png" class="name">
                <img src="../assets/image/erweima.png">
            </div>
        </div> -->
        <el-dialog title="请填写您的个人信息" :visible.sync="infoShow" width="30%" center>
            <span
                style="font-size: 12px;color: #A5A5A5;text-align: center;display: block;margin-top: -30px;">我们会在30分钟内联系您</span>

            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入您的姓名"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="tel">
                    <el-input v-model="ruleForm.tel" placeholder="请输入您的电话号码"></el-input>
                </el-form-item>
                <el-form-item label="留言" prop="content">
                    <el-input v-model="ruleForm.content" type="textarea" placeholder="请输入您的留言（不超过300字）"
                        class="inputC"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm('ruleForm')">立即提交</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import topView from '@/components/topView.vue'
import footerView from '@/components/footerView.vue'
import SideBar from '@/components/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            personalList: [],
            params: { pageSize: 8, pageNum: 1, personnelType: "1" },
            // imgurl:'http://192.168.0.199:8087/api',
            imgurl: '',
            caseList1: [],
            caseList2: [],
            ruleForm: {
                name: '',
                tel: '',
                content: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' },
                    // { min: 7, max: 11, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                tel: [
                    { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                content: [
                    // { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { min: 0, max: 300, message: '最多输入300字', trigger: 'blur' }
                ]

            },
            text:'',
        }
    },
    created() {
        this.getPersonalList()
        this.getcaseList1()
        this.getcaseList2()
        this.website()
        this.imgurl = this.$store.state.imgurl
        // this.$store.state.componentKey += 1
    },
    methods: {
        //获取人物列表
        getPersonalList() {
            // console.log("eeeeeeeeeee");
            axios.get('/api/pcsystem/TckgPersonnel/list', { params: this.params }).then((res) => {
                if (res.status == 200) {
                    this.personalList = res.data.rows
                    // console.log(this.personalList);
                }

            }).catch()
        },
        getcaseList1() {
            axios.get('/api/pcsystem/TckgCase/list', { params: { pageNum: 1, pageSize: 2, caseType: '1' } }).then((res) => {
                if (res.status == 200) {
                    this.caseList1 = res.data.rows
                    // console.log(this.caseList1);
                }
            }).catch()
        },
        getcaseList2() {
            axios.get('/api/pcsystem/TckgCase/list', { params: { pageNum: 2, pageSize: 2, caseType: '1' } }).then((res) => {
                if (res.status == 200) {
                    this.caseList2 = res.data.rows
                    // console.log(this.caseList2);
                }
            }).catch()
        },
        gotoAboutus() {
            this.$router.push('/aboutus')
        },
        gotoKuaiji() {
            this.$router.push({ path: '/kuaiji', query: { key: '2' } })
        },
        gotoQidai() {
            this.$router.push({ path: '/qidai', query: { key: '2' } })
        },
        gotoZonghe() {
            this.$router.push({ path: '/zonghe', query: { key: '2' } })
        },
        gotoHaiwai() {
            this.$router.push({ path: '/haiwai', query: { key: '3' } })
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '资质事业群' }).then((res) => {
                console.log(res);
            }).catch()
        },
        //提交信息
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    this.infoShow = false
                    console.log(this.ruleForm);
                    axios.post('/api/pcsystem/TckgConsult', { params: this.ruleForm }).then((res) => {
                        // console.log(res);
                        if (res.status == 200) {
                            // console.log(111111);
                            this.$message({
                                message: '提交成功，请耐心等待',
                                type: 'success'
                            });
                        }
                    }).catch()
                } else {
                    // this.$message({
                    //     message: '提交失败，请稍后重试',
                    //     type: 'warning'
                    // });
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        changeContent(item) {
            this.text = item.caseTitle2
            item.caseTitle2 = item.caseContent
            // console.log(item);
        },
        recoveryContent(item) {
            console.log(this.text);
            item.caseTitle2 = this.text
            // console.log(item);
        }
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    // height: 500px;
    // height: 450px;
    background-image: url("../assets/image/zizhiBack.png");
    background-size: 100% 100%;

    // div {
    //     margin: auto;
    //     padding-top: 80px;
    //     width: 1160px;
    //     color: #EA5514;
    //     text-align: center;
    // }

    // p {
    //     margin-bottom: 10px;
    //     color: #fff;
    //     font-size: 50px;
    //     font-weight: 700;
    // }

    // .english {
    //     display: block;
    //     font-size: 16px;
    //     color: #fff;
    // }

    // button {
    //     margin-top: 20px;
    //     width: 100px;
    //     height: 30px;
    //     line-height: 30px;
    //     color: #fff;
    //     background-color: #FFB258;
    //     border: 0;
    //     border-radius: 15px;
    // }
}

.kuaijiQ {
    margin: 60px auto;
    display: flex;
    justify-content: space-around;
    width: 1200px;

    img {
        width: 45%;
        border-radius: 0 !important;
    }

    div {
        width: 50%;
        margin-right: 20px;

        >p {
            margin-bottom: 10px;
            font-size: 22px;
            font-weight: 600;
        }

        .kuaijiQC {
            margin: 20px 0;
            width: 90%;
            font-size: 18px;
            font-weight: 400;
            line-height: 25px;
        }

    }

}

.canIdo {
    // position: relative;
    padding: 50px 0 20px;
    min-width: 1200px;
    padding-top: 60px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        margin: 50px auto;
        display: flex;
        justify-content: space-around;
        width: 1160px;

        img {
            width: 360px;
            height: 360px;
        }

        ul {
            margin-top: 100px;

            li {
                margin-bottom: 60px;
                width: 230px;

                p {
                    margin: 0 0 10px;
                    padding: 5px 10px;
                    // width: 100px;
                    width: 158px;
                    color: #fff;
                    text-align: center;
                    border-radius: 0px 20px 0px 20px;
                    // border-bottom-left-radius: 60%;
                    // border-top-right-radius: 60%;
                    background-color: #EA5514;
                    font-size: 24px;
                    font-weight: 500;
                }

                span {
                    font-size: 16px;
                    font-weight: 400;
                    color: #767676;
                }
            }

            &:last-child {
                // display: flex;
                // flex-direction: column;
                // justify-content: end;
                // align-items: end;
                text-align: end;

                li {

                    // position: relative;
                    p {
                        margin-left: 50px;
                        // position: absolute;
                        // right: 0;
                    }

                    // right: 0;
                }
            }
        }
    }

}

.service {
    // position: relative;
    padding: 0px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 50px auto 10px;
        width: 1160px;

        li {
            position: relative;
            margin-bottom: 1%;
            padding: 0 20px 30px;
            display: flex;
            flex-direction: column;
            // align-items: center;
            width: 29%;
            background-color: #fff;
            border-radius: 6px;
            cursor: pointer;

            >div {
                margin: 30px 0 20px;
                width: 70px;
                height: 80px;
                background-image: url('../assets/image/zizhi/chengbaoO.png');
                background-size: 100% 100%;
            }

            &:nth-child(2) {
                >div {
                    width: 80px;
                    height: 80px;
                    background-image: url('../assets/image/zizhi/shigongO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../assets/image/zizhi/shigongH.png');
                    }
                }
            }

            &:nth-child(3) {
                >div {
                    width: 80px;
                    height: 80px;
                    background-image: url('../assets/image/zizhi/maoziO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../assets/image/zizhi/maoziH.png');
                    }
                }
            }

            &:nth-child(4) {
                >div {
                    width: 80px;
                    height: 80px;
                    background-image: url('../assets/image/zizhi/anquanO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../assets/image/zizhi/anquanH.png');
                    }
                }
            }

            &:nth-child(5) {
                >div {
                    width: 70px;
                    height: 80px;
                    background-image: url('../assets/image/zizhi/chengzhuangO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../assets/image/zizhi/chengzhuangH.png');
                    }
                }
            }

            &:nth-child(6) {
                >div {
                    width: 80px;
                    height: 80px;
                    background-image: url('../assets/image/zizhi/fangziO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../assets/image/zizhi/fangziH.png');
                    }
                }
            }

            p {
                margin: 10px 0;
                font-size: 20px;
                font-weight: 600;
            }

            span {
                font-size: 14px;
                color: #B1B1B1;
            }

            &:hover {
                background-color: #EA5514;
                color: #fff;

                >div {
                    background-image: url('../assets/image/zizhi/chengbaoH.png');
                }

                span {
                    color: #fff;
                }
            }

            .qizhi {
                position: absolute;
                top: -10px;
                right: 40px;
                margin: 10px 0;
                width: 35px;
                height: 50px;
                color: #F2956C;
                text-align: center;
                line-height: 40px;
                background-image: url('../assets/image/zizhi/qizhi.png');
                background-size: 100% 100%;
            }
        }
    }

}

.personal {
    // position: relative;
    padding: 30px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        margin: 40px auto 0;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        width: 1160px;

        li {
            position: relative;
            margin-top: 1vh;
            margin-left: 10px;
            width: 24%;
            height: 340px;
            // background-image: url("../assets/image/personal.png");
            background-size: 100% 100%;
            // border-radius: 0;
            border-radius: 6px;
            overflow: hidden;

            // overflow: hidden;
            >p {
                margin: 0;
                position: absolute;
                bottom: 0;
                padding: 14px;
                width: 89.5%;
                background-color: rgba(0, 0, 0, 0.5);
                text-align: start;
                // border-radius: 0;
                color: #fff;
            }

            .personalT {
                display: block;
                font-size: 18px;
                font-weight: 600;
            }

            .personalC {
                font-size: 16px;
                font-weight: 500;
            }
        }
    }

    button {
        margin: 50px 0 50px;
        // margin-left: 46%;
        // margin-left: 45%;
        // min-width: 8%;
        width: 202px;
        height: 56px;
        padding: 5px 15px;
        // font-size: 26px;
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        // background-color: #FE8F3F;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;
        text-align: center;
    }
}

.flow {
    // position: relative;
    padding: 50px 0;
    min-width: 1200px;
    background-image: url("../assets/image/kuaiji/6458486361c124a42770f394d12bef7d526a503611913d-0S20U5_fw1200.png");

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >ul {
        margin: 140px auto 20px;
        // padding: 0 0 20px;
        display: flex;
        // flex-wrap: wrap;
        width: 1160px;
        height: 200px;
        background-image: url("../assets/image/zizhi/line.png");
        background-size: 100% 50%;
        background-repeat: no-repeat;

        li {
            width: 150px;
            height: 100px;
            // text-align: center;
            color: #fff;

            &:nth-child(odd) {
                margin-top: 100px;
                margin-left: 50px;
                background-image: url('../assets/image/zizhi/duihuaKB.png');
                background-size: 100% 100%;

                p {
                    // margin-top: 10px;
                    margin-left: 20px;
                    font-size: 20px;
                    text-align: start;
                }

                span {
                    display: block;
                    margin: 35px 20px 7px;
                    font-size: 16px;
                }
            }

            &:nth-child(even) {
                margin-top: -100px;
                // margin-left: 40px;
                background-image: url('../assets/image/zizhi/duihuaKT.png');
                background-size: 100% 100%;

                p {
                    margin-top: 10px;
                    margin-left: 20px;
                    font-size: 20px;
                    text-align: start;
                }

                span {
                    display: block;
                    margin: 7px 20px;
                    font-size: 16px;
                }
            }

            &:nth-child(2) {
                margin-left: 5px;
            }

            &:nth-child(3) {
                margin-left: -5px;
            }

            &:nth-child(4) {
                margin-left: 5px;
            }

            &:nth-child(5) {
                margin-left: 0px;
            }

            &:nth-child(6) {
                margin-left: 25px;
            }

            &:nth-child(7) {
                margin-left: 15px;
            }
        }
    }

}

.advantages {
    // position: relative;
    padding: 50px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        display: flex;
        margin: 40px auto;
        width: 1160px;

        // justify-content: space-around;
        img {
            width: 50%;
        }

        ul {
            margin-top: 20px;
            margin-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 50%;
            height: 340px;

            li {
                display: flex;
                margin-top: 20px;
                padding: 8px 15px;
                width: 90%;
                background-color: #fff;
                border-radius: 6px;

                div {
                    width: 30px;
                    height: 30px;
                    background-image: url('../assets/image/kuaiji/quan.png');
                    background-size: 100% 100%;
                    text-align: center;
                    line-height: 30px;
                    color: #fff;
                    font-weight: 600;
                }

                p {
                    margin-left: 20px;
                    line-height: 28px;
                    font-size: 20px;
                }

                &:hover {
                    margin-left: -5%;
                    width: 95%;
                    background-color: #EA5514;
                    color: #fff;

                    div {
                        background-image: url('../assets/image/kuaiji/quanH.png');
                        color: #EA5514;
                    }
                }
            }
        }
    }

    button {
        margin: 30px 0;
        margin-left: 47%;
        // width: 202px;
        // height: 56px;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        // background-color: #FE8F3F;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;
    }
}

.success {
    // position: relative;
    padding: 60px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        display: flex;
        justify-content: space-around;
        margin: 50px auto 0;
        width: 1160px;

        >ul {
            // display: flex;
            // justify-content: space-around;
            // flex-wrap: wrap;
            // margin: 30px auto 0;
            // width: 1160px;
            width: 49%;
            height: 500px;
            overflow: hidden;

            >li {
                display: flex;
                margin-bottom: 20px;
                padding: 20px 15px;
                width: 94%;
                // height: 40%;
                background-color: #fff;
                border-radius: 6px;
                overflow: hidden;

                >img {
                    width: 30%;
                    height: 200px;
                    // height: 20px;
                }

                >div {
                    position: relative;
                    width: 70%;
                    height: 200px;
                    margin-left: 10px;
                    // position: relative;
                    text-align: start;

                    // width: 500px;
                    p {
                        margin-top: 10px;
                        text-align: start;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    span {
                        display: block;
                        margin-top: 10px;
                        font-size: 14px;
                        line-height: 25px;
                        color: #AFAFAF;
                        // display: -webkit-box;
                        // -webkit-box-orient: vertical;
                        // -webkit-line-clamp: 4;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                    }

                    button {
                        margin-top: 5px;
                        padding: 2px 5px;
                        border: 0;
                        border-radius: 2px;
                        background-color: #E6F2CC;
                        color: #A4C361;
                    }

                    .gengduo {
                        position: absolute;
                        right: 0px;
                        top: 10px;
                        width: 20px;
                        height: 4px;
                        background-image: url('../assets/image/gengduo.png');
                        background-size: 100% 100%;
                    }
                }

                &:hover {
                    // position: absolute;
                    // position: relative;
                    // height: 455px;
                    height: 460px;
                    color: #fff;
                    background-color: #EA5513;

                    img {
                        width: 0;
                    }

                    div {
                        width: 95%;

                        span {
                            color: #fff;
                            display: block;
                        }

                        button {
                            color: #fff;
                            background-color: #EE824E;
                        }
                    }

                    .gengduo {
                        display: none;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                    &:hover {
                        position: relative;
                        // top: -262px;
                        top: -260px;
                    }
                }
            }
        }
    }


}

.offer {
    // position: relative;
    padding: 30px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        display: flex;
        justify-content: space-around;
        margin: 50px auto;
        width: 1160px;

        li {
            // padding: 20px;
            width: 24%;
            background-color: #fff;
            border-radius: 6px;
            text-align: center;

            p {
                margin: auto;
                padding: 40px 40px 20px;
                width: 40%;
                text-align: center;
                border-bottom: 1px dashed #E1E1E1;

                img {
                    // margin: auto;
                    width: 50px;
                    height: 50px;
                }

                span {
                    display: block;
                    margin-top: 10px;
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            button {
                margin: 20px 0;
                padding: 5px 15px;
                color: #fff;
                background-color: #EA5514;
                border-radius: 15px;
                border: 0;
            }

            &:nth-child(1) {
                img {
                    // margin-top: -5px;
                    width: 75px;
                    height: 65px;
                }

                p {
                    padding-top: 35px;

                    span {
                        margin-top: 0px;
                    }
                }
            }

            &:nth-child(3) {
                img {
                    width: 60px;
                    height: 60px;
                }

                p {
                    padding-top: 35px;

                    span {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

.orange {
    padding: 70px 0;
    width: 100%;
    min-width: 1200px;
    // height: 140px;
    background-image: url("../assets/image/橙色背景.png");
    background-size: 100% 100%;
    text-align: center;
    color: #fff;
    font-size: 40px;

    img {
        position: relative;
        top: -10px;

        &:nth-child(2) {
            transform: rotate(180deg) !important;
        }
    }

    // line-height: 150px;
    span {
        display: block;
        margin-top: 20px;
        font-size: 22px;

        // line-height: 10px;

    }
}

.info {
    margin: auto;
    position: relative;
    width: 350px;
    border-radius: 6px;
    text-align: center;
    overflow: hidden;
    background-color: #fff;

    p {
        padding: 20px 40px 40px;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        background-color: #EA5513;
    }

    .name {
        position: absolute;
        top: 60px;
        left: 140px;
        width: 50px;
        height: 50px;
    }

    .cheng {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 25px;
        height: 25px;

    }

    >img {
        &:last-child {
            margin: 40px 0 20px;
            width: 70%;
        }

    }
}

::v-deep .el-dialog--center .el-dialog__body {
    padding-bottom: 0;
}

::v-deep .el-form {
    margin-top: 30px;
    margin-left: -20px;

    .el-form-item {
        .el-form-item__label {
            width: 70px !important;
        }

        .el-form-item__content {
            margin-left: 70px !important;
        }
    }
}

::v-deep .el-textarea__inner {
    height: 150px !important;
}
</style>