import Vue from 'vue'
import VueRouter from 'vue-router'
import indexView from '../views/indexView.vue'
import KuaijiView from '@/views/kuaijiView.vue';
import ZizhiView from '@/views/zizhiView.vue';
import QidaiView from '@/views/qidaiView.vue';
import ZongheView from '@/views/zongheView.vue';
import HaiwaiView from '@/views/haiwaiView.vue';
import ShangyeView from '@/views/shangyeView.vue';
import ZixunView from '@/views/zixunView.vue';
import AboutusView from '@/views/aboutusView.vue';
import DongshizhangView from '@/views/dongshizhangView.vue';
import QiyewenhuaView from '@/views/qiyewenhuaView.vue';
import JituangouchengView from '@/views/jituangouchengView.vue';
import FazhanlichengView from '@/views/fazhanlichengView.vue';
import QuanqiubujvView from '@/views/quanqiubujvView.vue';
import XiaoqiView from '@/views/xiaoqiView.vue';
import ShangxiehuiView from '@/views/shangxiehuiView.vue';
import GongyiView from '@/views/gongyiView.vue';
import ContentView from '@/views/contentView.vue';

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: '/',
    component: indexView
  }, 
  {
    path: '/kuaiji',
    name: '/',
    component: KuaijiView
  }, 
  {
    path: '/zizhi',
    name: '/',
    component: ZizhiView
  }, 
  {
    path: '/qidai',
    name: '/',
    component: QidaiView
  }, 
  {
    path: '/zonghe',
    name: '/',
    component: ZongheView
  },
  {
    path: '/haiwai',
    name: '/',
    component: HaiwaiView
  },  
  {
    path: '/shangye',
    name: '/',
    component: ShangyeView
  }, 
  {
    path: '/zixun',
    name: '/',
    component: ZixunView
  }, 
  {
    path: '/aboutus',
    name: '/',
    component: AboutusView
  }, 
  {
    path: '/dongshizhang',
    name: '/',
    component: DongshizhangView
  }, 
  {
    path: '/qiyewenhua',
    name: '/',
    component: QiyewenhuaView
  }, 
  {
    path: '/jituangoucheng',
    name: '/',
    component: JituangouchengView
  }, 
  {
    path: '/fazhanlicheng',
    name: '/',
    component: FazhanlichengView
  }, 
  {
    path: '/quanqiubujv',
    name: '/',
    component: QuanqiubujvView
  }, 
  {
    path: '/xiaoqi',
    name: '/',
    component: XiaoqiView
  },
  {
    path: '/shangxiehui',
    name: '/',
    component: ShangxiehuiView
  },
  {
    path: '/gongyi',
    name: '/',
    component: GongyiView
  },
  {
    path: '/content',
    name: '/',
    component: ContentView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
