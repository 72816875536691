<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
            <!-- <div>
                <p>走进唐诚</p>
                <span class="english">ENTERING TANG CHENG</span>
                <button>了解更多</button>
            </div> -->
        </div>
        <div class="jiyu">
            <p><img src="../assets/image/left.png">&nbsp;董事长寄语&nbsp;<img src="../assets/image/right.png"></p>
            <span>CHAIRMAN MESSAGE</span>
            <div>
                <div>
                    <div>
                        <p>创始人寄语
                            <img src="../assets/image/jiyu/jiantou.png" alt="" style="position: relative;top: 17px;">
                        </p>
                        <img src="../assets/image/jiyu/line.png" alt="">
                        <br>
                        <span class="title">{{ info.chairmanTitle }}</span>
                        <!-- <img src="../assets/image/jiyu/title.png" alt=""> -->
                        <!-- <br> -->
                        <span>{{ info.chairmanStitle }}</span>
                    </div>
                    <img :src="imgurl + info.chairmanImg" alt="">
                </div>
                <p class="content" v-html="info.chairmanContent"></p>
                <img src="../assets/image/jiyu/jiantou.png" alt="" style="position: relative;top: 17px;left: 550px;">
                <footer>{{ footerT }}<span>·{{ footerC }}</span></footer>
            </div>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>

    </div>
</template>

<script>
import topView from '@/components/topView.vue'
import footerView from '@/components/footerView.vue'
import SideBar from '@/components/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            info: {},
            // imgurl: 'http://192.168.0.199:8087/api',
            imgurl: '',
            footerT: '',
            footerC: ''
        }
    },
    created() {
        this.getinfo()
        // this.$store.state.componentKey += 1
        this.website()
        this.imgurl = this.$store.state.imgurl
    },
    methods: {
        getinfo() {
            axios.get('/api/pcsystem/TckgChairman/getInfo').then((res) => {
                if (res.status == 200) {
                    this.info = res.data.data
                    this.footerT = res.data.data.chairmanBrief.split('·')[0]
                    this.footerC = res.data.data.chairmanBrief.split('·')[1]
                    // console.log(res.data.data.chairmanBrief.split('·'));
                }
            }).catch()
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '董事长寄语' }).then((res) => {
                console.log(res);
            }).catch()
        },
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    // height: 450px;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    // height: 500px;
    background-image: url("../assets/image/dongshizhangBack.png");
    background-size: 100% 100%;

    // div {
    //     margin: auto;
    //     padding-top: 80px;
    //     width: 1160px;
    //     color: #EA5514;
    //     text-align: center;
    // }

    // p {
    //     margin: 20px 0;
    //     color: #fff;
    //     font-size: 50px;
    //     font-weight: 700;
    // }

    // .english {
    //     display: block;
    //     font-size: 16px;
    //     color: #fff;
    // }

    // button {
    //     margin-top: 30px;
    //     width: 100px;
    //     height: 30px;
    //     line-height: 30px;
    //     color: #fff;
    //     background-color: #FFB258;
    //     border: 0;
    //     border-radius: 15px;
    // }
}

.jiyu {
    position: relative;
    // margin: auto;
    padding: 30px 0;
    min-width: 1200px;
    // background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        margin: 40px auto;
        width: 1200px;
        text-align: center;

        >div {
            display: flex;
            justify-content: space-between;

            div {
                text-align: start;

                p {
                    margin: 10px 0;
                    font-size: 28px;
                    font-weight: 500;

                    img {}
                }

                >img {
                    margin: 10px 0;

                    &:nth-child(4) {
                        margin-top: 40px;
                        width: 250px;
                    }
                }

                span {
                    font-size: 22px;
                    color: #333333;
                    line-height: 34px;
                }

            }

            >img {
                width: 200px;
                height: 200px;
            }

        }

        .content {
            margin-top: 30px;
            padding: 30px;
            background-image: url('../assets/image/jiyu/back.png');
            background-size: 100% 100%;
            line-height: 40px;
            font-size: 20px;
            text-align: start;
        }

    }

    footer {
        margin-top: 20px;
        text-align: end;
        font-size: 28px;
        line-height: 40px;

        span {
            color: #767676;
        }
    }

    .title {
        display: block;
        margin: 20px 0 10px;
        font-size: 34px;
        font-weight: 600;
        color: #EA5514;
    }
}
</style>