<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
            <!-- <div>
                <p>海外事业群</p>
                <span class="english">赋能跨境&nbsp;·&nbsp;合规出海</span>
                <button @click="gotoAboutus">了解更多</button>
            </div> -->
            <button><a href="https://vesaas.com">查看更多</a></button>
        </div>
        <div class="kuaijiQ">
            <div>
                <p>海外事业群</p>
                <div
                    style="margin-bottom: 40px;width: 81px;height: 4px;background: linear-gradient( 270deg, #F4F4F4 0%, #EA5514 100%);border-radius: 1px;">
                </div>
                <p class="kuaijiQC">
                    从服务中国企业到服务全球企业，海外事业群作为唐诚战略版图上重彩之笔，是亚马逊、速卖通两大电商平台SPN官方认证财税服务商。

                </p>
                <p class="kuaijiQC">
                    已服务跨境电商卖家超过2万+，在香港、深圳、广州、北京、上海设有专业团队赋能，在新加坡、美国、越南、阿联酋、英国、德国、法国设有子公司事务所，多国分布唐诚自有专业会计、审计、法律团队赋能企业跨境合规。

                </p>
                <!-- <ul>
                    <li>
                        <img src="../assets/image/qidai/shujvcaiji.png">
                        <span>
                            客户触达
                        </span>
                    </li>
                    <li>
                        <img src="../assets/image/qidai/kehuchuda.png">
                        <span>数据采集</span>
                    </li>
                    <li>
                        <img src="../assets/image/qidai/moxingpingjia.png">
                        <span>模型评价</span>
                    </li>
                </ul> -->
            </div>
            <img src="../assets/image/haiwai/shiyequnB.png">
        </div>
        <div style="padding: 30px 0;background-color: #F4F4F4;min-width: 1200px;">
            <ul class="kunnan">
                <li>
                    <!-- <img src="../assets/image/zonghe/591a4aa4eefc273ead8250e88db7dbe1.png"> -->
                    <p>
                        <span>语言沟通难</span>
                        <!-- <span>根据公司现状进行详细评估</span> -->
                    </p>
                    <div>
                        <p>语言沟通难</p>
                        <div></div>
                        <span>无法与当地政府机构律师、会计沟通</span>
                    </div>
                </li>
                <li>
                    <!-- <img src="../assets/image/zonghe/591a4aa4eefc273ead8250e88db7dbe1.png"> -->
                    <p>
                        <span>海外规则复杂</span>
                        <!-- <span>根据公司现状进行详细评估</span> -->
                    </p>
                    <div>
                        <p>海外规则复杂</p>
                        <div></div>
                        <span>需要了解当地的法规和政策并遵守当地的规定</span>
                    </div>
                </li>
                <li>
                    <!-- <img src="../assets/image/zonghe/2cff48d3fc98bc9ec85a87ed7f2716d4.png"> -->
                    <p>
                        <span>时间成本高</span>
                        <!-- <span>根据公司现状进行详细评估</span> -->
                    </p>
                    <div>
                        <p>时间成本高</p>
                        <div></div>
                        <span>不了解审批部门对不同行业的潜在资质要求，都有可能到时审批不通</span>
                    </div>
                </li>
                <li>
                    <!-- <img src="../assets/image/zonghe/2cff48d3fc98bc9ec85a87ed7f2716d4.png"> -->
                    <p>
                        <span>银行开户难</span>
                        <!-- <span>根据公司现状进行详细评估</span> -->
                    </p>
                    <div>
                        <p>银行开户难</p>
                        <div></div>
                        <span>可能会涉及到签证和旅行安排等问题</span>
                    </div>
                </li>
            </ul>
        </div>
        <div class="canIdo">
            <p><img src="../assets/image/left.png">&nbsp;我们可以为您的企业做什么&nbsp;<img src="../assets/image/right.png"></p>
            <span>WHAT CAN WE DO FOR YOUR BUSINESS</span>
            <div>
                <ul>
                    <li>
                        <p>
                            <span>跨境合规</span>
                            <span>提供咨询、实施合规政策；进行风险评估，提供解决方案</span>
                        </p>
                    </li>
                    <li>
                        <!-- <img src="../assets/image/zonghe/591a4aa4eefc273ead8250e88db7dbe1(1).png"> -->
                        <p>
                            <span>运营方案</span>
                            <span>定制详细计划，帮助企业有效地管理资源、提高盈利能力</span>
                        </p>
                    </li>
                    <li>
                        <!-- <img src="../assets/image/zonghe/2cff48d3fc98bc9ec85a87ed7f2716d4.png"> -->
                        <p>
                            <span>入门指导</span>
                            <span>专业团队手把手指导，助企业快速起步</span>
                        </p>
                    </li>
                </ul>
                <img src="../assets/image/haiwai/canDo.png">
                <ul>
                    <li>
                        <!-- <img src="../assets/image/zonghe/591a4aa4eefc273ead8250e88db7dbe1.png"> -->
                        <p>
                            <span>降低税负</span>
                            <span>通过规划合法降低税负，省心、省钱</span>
                        </p>
                    </li>
                    <li>
                        <!-- <img src="../assets/image/zonghe/591a4aa4eefc273ead8250e88db7dbe1(1).png"> -->
                        <p>
                            <span>提升竞争力</span>
                            <span>帮助企业在不同层次提升竞争力，让企业赢在起跑线</span>
                        </p>
                    </li>
                    <li>
                        <!-- <img src="../assets/image/zonghe/2cff48d3fc98bc9ec85a87ed7f2716d4.png"> -->
                        <p>
                            <span>专项扶持</span>
                            <span>1V1全方位扶持，为企业量身打造最强引擎</span>
                        </p>
                    </li>
                </ul>
            </div>

        </div>
        <div class="orange">
            <img src="../assets/image/kuaiji/whiteLine.png">&nbsp;&nbsp;低门槛，快速跑通海外业务&nbsp;&nbsp;<img
                src="../assets/image/kuaiji/whiteLine.png">
        </div>
        <div class="personal">
            <p><img src="../assets/image/left.png">&nbsp;资深专家&nbsp;<img src="../assets/image/right.png"></p>
            <span>SENIOR EXPERTS</span>
            <ul>
                <li v-for="item in personalList" :key="item.personnelId">
                    <img :src="imgurl + item.personnelImg" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">{{ item.personnelName }}</span>
                        <span class="personalC">{{ item.personnelPosition }}</span>
                    </p>
                </li>
                <!-- <li>
                    <img src="../assets/image/qidai/personal10.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">杜少鹏</span>
                        <span class="personalC">唐诚资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/qidai/personal16.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">代振坤</span>
                        <span class="personalC">唐诚石家庄资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/qidai/personal15.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">沈杰</span>
                        <span class="personalC">唐诚北京资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/qidai/personal9.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">张娟竺</span>
                        <span class="personalC">唐诚广州资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/qidai/personal11.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">郑姣姣</span>
                        <span class="personalC">唐诚天津资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/qidai/personal12.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">孔候平</span>
                        <span class="personalC">唐诚太远资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/qidai/personal15.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">东倩</span>
                        <span class="personalC">唐诚廊坊资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/qidai/personal14.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">赵晓东</span>
                        <span class="personalC">唐诚石家庄资质事业部高级顾问</span>
                    </p>
                </li> -->
            </ul>
        </div>
        <div class="service">
            <p><img src="../assets/image/left.png">&nbsp;服务内容&nbsp;<img src="../assets/image/right.png"></p>
            <span>SERVICE CONTENT</span>
            <div class="top">
                <div class="topLeft">
                    <div></div>
                    <p>对外投资备案ODI/<br>37号返投备案/<br>海关进出口备案</p>
                </div>
                <ul class="topRight">
                    <li>
                        <div></div>
                        <!-- <p class="qizhi">01</p> -->
                        <span>
                            <p>海外银行开户</p>
                            <span>不成功全额退款</span>
                        </span>
                        <!-- <img src="../assets/image/qidai/bottomLine.png" class="bottom"> -->
                    </li>
                    <li>
                        <div></div>
                        <!-- <p class="qizhi">02</p> -->
                        <!-- <img src="../assets/image/kuaiji/biangengO.png"> -->
                        <span>
                            <p>VIE搭建</p>
                            <span>保护客户公司资产</span>
                        </span>

                    </li>
                    <li>
                        <div></div>
                        <!-- <p class="qizhi">03</p> -->
                        <!-- <img src="../assets/image/kuaiji/yichangO.png"> -->
                        <span>
                            <p>外汇资金管理</p>
                            <span>为客户实现资金最优配置</span>
                        </span>
                    </li>
                    <li>
                        <div></div>
                        <!-- <p class="qizhi">04</p> -->
                        <!-- <img src="../assets/image/kuaiji/jizhangO.png"> -->
                        <span>
                            <p>海关稽查退税</p>
                            <span>熟悉海关现行税收稽查</span>
                        </span>
                    </li>
                    <li>
                        <div></div>
                        <!-- <p class="qizhi">05</p> -->
                        <!-- <img src="../assets/image/kuaiji/shenjiO.png"> -->
                        <span>
                            <p>股权及顶层架构设计</p>
                            <span>专业人士起草设计</span>
                        </span>
                    </li>
                    <li>
                        <div></div>
                        <!-- <p class="qizhi">06</p> -->
                        <!-- <img src="../assets/image/kuaiji/shougouO.png"> -->
                        <span>
                            <p>VAT/GST/Sales tax</p>
                            <span>增值税/消费税/销售税</span>
                        </span>
                    </li>
                </ul>
            </div>
            <ul class="bottom">
                <li>
                    <div></div>
                    <!-- <p class="qizhi">01</p> -->
                    <span>
                        <p>出口退税</p>
                        <span>快速办理，轻松节省成本</span>
                    </span>
                    <!-- <img src="../assets/image/qidai/bottomLine.png" class="bottom"> -->
                </li>
                <li>
                    <div></div>
                    <!-- <p class="qizhi">02</p> -->
                    <!-- <img src="../assets/image/kuaiji/biangengO.png"> -->
                    <span>
                        <p>全球合规架构搭建/<br>
                            国际税筹</p>
                        <!-- <span>保护客户公司资产</span> -->
                    </span>

                </li>
                <li>
                    <div></div>
                    <!-- <p class="qizhi">03</p> -->
                    <!-- <img src="../assets/image/kuaiji/yichangO.png"> -->
                    <span>
                        <p>各国工商注册/<br>
                            税务年报申报/审计/<br>
                            盘点</p>
                        <!-- <span>为客户实现资金最优配置</span> -->
                    </span>
                </li>
                <li>
                    <div></div>
                    <!-- <p class="qizhi">04</p> -->
                    <!-- <img src="../assets/image/kuaiji/jizhangO.png"> -->
                    <span>
                        <p>EPR</p>
                        <span>包装法，英代欧代美代，
                            weee, 电池法等</span>
                    </span>
                </li>
            </ul>

            <div style="display: flex;justify-content: center;"><button @click="infoShow = true">点击咨询</button></div>
        </div>

        <div class="flow">
            <p><img src="../assets/image/left.png">&nbsp;服务流程&nbsp;<img src="../assets/image/right.png"></p>
            <span>SERVICE FLOW</span>
            <div style="margin: 40px auto;width: 1196px;border: 2px solid #F2D5C8;"></div>
            <ul>
                <li>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <div></div>
                    <p>01</p>
                    <span>初始尽调</span>
                </li>
                <li>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <div></div>
                    <p>02</p>
                    <span>梳理报告</span>
                </li>
                <li>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <div></div>
                    <p>03</p>
                    <span>执行方案解读</span>
                </li>
                <li>
                    <div></div>
                    <p>04</p>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <span>落地流程</span>
                </li>
                <li>
                    <div></div>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <p>05</p>
                    <span>业务资金流指导</span>
                </li>
                <li>
                    <div></div>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <p>06</p>
                    <span>模型设置指导<br>合规账务财务</span>
                </li>
                <li>
                    <div></div>
                    <p>07</p>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <span>过程执行咨询顾问</span>
                </li>
                <li>
                    <div></div>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <p>08</p>
                    <span>财务组织优化</span>
                </li>
                <li>
                    <div></div>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <p>09</p>
                    <span>跨境税务方案</span>
                </li>
            </ul>
        </div>

        <div class="advantages">
            <p><img src="../assets/image/left.png">&nbsp;服务优势&nbsp;<img src="../assets/image/right.png"></p>
            <span>SERVICE ADVANTAGES</span>
            <div>
                <img src="../assets/image/haiwai/advanB.png">
                <ul>
                    <li>
                        <div>01</div>
                        <p>香港、英国、美国等地专业会计、律师团队</p>
                    </li>
                    <li>
                        <div>02</div>
                        <p>多家十亿级跨境电商大卖家财务顾问服务经验</p>
                    </li>
                    <li>
                        <div>03</div>
                        <p>强大的报关实力</p>
                    </li>
                    <li>
                        <div>04</div>
                        <p>自主退税系统</p>
                    </li>
                </ul>
            </div>
            <!-- <button @click="infoShow = true">点击咨询</button> -->
        </div>

        <div class="success">
            <p><img src="../assets/image/left.png">&nbsp;成功案例&nbsp;<img src="../assets/image/right.png"></p>
            <span>SUCCESS STORIES</span>
            <ul>
                <li v-for="item in caseList" :key="item.caseId" @mouseenter="changeContent(item)" @mouseleave="recoveryContent(item)">
                    <img :src="imgurl + item.caseImg">
                    <div>
                        <p>项目：{{ item.caseTitle }}</p>
                        <button>耗时：{{ item.caseHs }}</button>
                        <span v-html="item.caseTitle2"></span>
                        <div class="gengduo"></div>
                    </div>
                </li>
                <!-- <li>
                    <img src="../assets/image/qidai/4a93911dec3267039bfa4b6d8fd1d3e6.png">
                    <div>
                        <p>项目：财务外包</p>
                        <button>耗时：10天</button>
                        <span>客户是AliExpress平台头部卖家，机缘巧合下平台小二联系了客户，说客户的品类适合做本地市场，于是客户第一家财税公司就是找我们了解的，客户的需求就是公司注册费用以及次年维护费低一点，最好是能够避税的小众国家，那我们给客户匹配到的刚好是立陶宛这个国家，各方面都跟客户想要找的很贴合。客户会一直犹豫的原因主要是注册费用以及店铺KYC审核提供的水电/地址
                            /银行账单是否能够提供，协助之后跟客户说都会配合提供，在后续店铺的审核期间，我们接近2个月的时候一直协助客户制定文件，指导资料的填写等等。
                            继而后续客户本地店铺顺利开展之后，也入驻了意大利/法国仓库。因为有了以上事宜，客户觉得我们总体服务态度以及专业上他是很认可的，于是说把意大利/法国这两个国家的财税一起交给我们做，后续申报上他也可以少操心。</span>

                    </div>
                </li>
                <li>
                    <img src="../assets/image/qidai/4a93911dec3267039bfa4b6d8fd1d3e6(1).png">
                    <div>
                        <p>项目：财务外包111</p>
                        <button>耗时：10天</button>
                        <span>客户是AliExpress平台头部卖家，机缘巧合下平台小二联系了客户，说客户的品类适合做本地市场，于是客户第一家财税公司就是找我们了解的，客户的需求就是公司注册费用以及次年维护费低一点，最好是能够避税的小众国家，那我们给客户匹配到的刚好是立陶宛这个国家，各方面都跟客户想要找的很贴合。客户会一直犹豫的原因主要是注册费用以及店铺KYC审核提供的水电/地址
                            /银行账单是否能够提供，协助之后跟客户说都会配合提供，在后续店铺的审核期间，我们接近2个月的时候一直协助客户制定文件，指导资料的填写等等。
                            继而后续客户本地店铺顺利开展之后，也入驻了意大利/法国仓库。因为有了以上事宜，客户觉得我们总体服务态度以及专业上他是很认可的，于是说把意大利/法国这两个国家的财税一起交给我们做，后续申报上他也可以少操心。</span>

                    </div>
                </li>
                <li>
                    <img src="../assets/image/qidai/4a93911dec3267039bfa4b6d8fd1d3e6(2).png">
                    <div>
                        <p>项目：财务外包</p>
                        <button>耗时：10天</button>
                        <span>客户是AliExpress平台头部卖家，机缘巧合下平台小二联系了客户，说客户的品类适合做本地市场，于是客户第一家财税公司就是找我们了解的，客户的需求就是公司注册费用以及次年维护费低一点，最好是能够避税的小众国家，那我们给客户匹配到的刚好是立陶宛这个国家，各方面都跟客户想要找的很贴合。客户会一直犹豫的原因主要是注册费用以及店铺KYC审核提供的水电/地址
                            /银行账单是否能够提供，协助之后跟客户说都会配合提供，在后续店铺的审核期间，我们接近2个月的时候一直协助客户制定文件，指导资料的填写等等。
                            继而后续客户本地店铺顺利开展之后，也入驻了意大利/法国仓库。因为有了以上事宜，客户觉得我们总体服务态度以及专业上他是很认可的，于是说把意大利/法国这两个国家的财税一起交给我们做，后续申报上他也可以少操心。f</span>

                    </div>
                </li> -->
            </ul>
            <!-- <button @click="infoShow = true">点击咨询</button> -->
        </div>
        <div class="offer">
            <!-- <p><img src="../assets/image/left.png">&nbsp;我们还提供这些服务&nbsp;<img src="../assets/image/right.png"></p>
            <span>WE ALSO OFFER THESE SERVICES</span> -->
            <ul>
                <li>
                    <p>
                        <img src="../assets/image/kuaiji/jiangpai.png">
                        <span>建筑资质</span>
                    </p>
                    <button @click="gotoZizhi">了解更多</button>
                </li>
                <li>
                    <p>
                        <img src="../assets/image/kuaiji/qianbao.png">
                        <span>金融服务</span>
                    </p>
                    <button @click="gotoQidai">了解更多</button>
                </li>
                <li>
                    <p>
                        <img src="../assets/image/kuaiji/caishui.png">
                        <span>财税服务</span>
                    </p>
                    <button @click="gotoKuaiji">了解更多</button>
                </li>
                <li>
                    <p>
                        <img src="../assets/image/kuaiji/zhishi.png">
                        <span>知识产权</span>
                    </p>
                    <button @click="gotoZonghe">了解更多</button>
                </li>
            </ul>
        </div>
        <div class="btn">
            <button><a href="https://vesaas.com">查看更多</a></button>
        </div>
        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>
        <!-- <div v-if="infoShow"
            style="padding-top: 200px;width:100vw;height: 100vh;background-color:rgba(0, 0, 0, 0.4);position: fixed;top: 0">
            <div class="info">
                <p>扫码关注微信公众号</p>
                <img src="../assets/icon/cheng.png" class="cheng" @click="infoShow = false">
                <img src="../assets/image/name.png" class="name">
                <img src="../assets/image/erweima.png">
            </div>
        </div> -->
        <el-dialog title="请填写您的个人信息" :visible.sync="infoShow" width="30%" center>
            <span
                style="font-size: 12px;color: #A5A5A5;text-align: center;display: block;margin-top: -30px;">我们会在30分钟内联系您</span>

            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入您的姓名"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="tel">
                    <el-input v-model="ruleForm.tel" placeholder="请输入您的电话号码"></el-input>
                </el-form-item>
                <el-form-item label="留言" prop="content">
                    <el-input v-model="ruleForm.content" type="textarea" placeholder="请输入您的留言（不超过300字）"
                        class="inputC"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm('ruleForm')">立即提交</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import topView from '@/components/topView.vue'
import footerView from '@/components/footerView.vue'
import SideBar from '@/components/sideBar.vue'
import axios from 'axios';


export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            personalList: [],
            params: { pageSize: 8, pageNum: 1, personnelType: "4" },
            // imgurl: 'http://192.168.0.199:8087/api',
            imgurl: '',
            caseList: [],
            ruleForm: {
                name: '',
                tel: '',
                content: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' },
                    // { min: 7, max: 11, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                tel: [
                    { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                content: [
                    // { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { min: 0, max: 300, message: '最多输入300字', trigger: 'blur' }
                ]

            },
            text:'',
        }
    },
    created() {
        this.getPersonalList()
        this.getcaseList()
        this.website()
        this.imgurl = this.$store.state.imgurl
        // this.$store.state.componentKey += 1
    },
    methods: {
        getPersonalList() {
            axios.get('/api/pcsystem/TckgPersonnel/list', { params: this.params }).then((res) => {
                if (res.status == 200) {
                    this.personalList = res.data.rows
                    // console.log(this.personalList);
                }

            }).catch()
        },
        getcaseList() {
            axios.get('/api/pcsystem/TckgCase/list', { params: { pageNum: 1, pageSize: 3, caseType: '4' } }).then((res) => {
                if (res.status == 200) {
                    this.caseList = res.data.rows
                    console.log(this.caseList);
                }
            }).catch()
        },
        gotoAboutus() {
            this.$router.push('/aboutus')
        },
        gotoZonghe() {
            this.$router.push({ path: '/zonghe', query: { key: '2' } })
        },
        gotoQidai() {
            this.$router.push({ path: '/qidai', query: { key: '2' } })
        },
        gotoZizhi() {
            this.$router.push({ path: '/zizhi', query: { key: '2' } })
        },
        gotoKuaiji() {
            this.$router.push({ path: '/kuaiji', query: { key: '2' } })
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '唐诚国际' }).then((res) => {
                console.log(res);
            }).catch()
        },
        //提交信息
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    this.infoShow = false
                    console.log(this.ruleForm);
                    axios.post('/api/pcsystem/TckgConsult', { params: this.ruleForm }).then((res) => {
                        // console.log(res);
                        if (res.status == 200) {
                            // console.log(111111);
                            this.$message({
                                message: '提交成功，请耐心等待',
                                type: 'success'
                            });
                        }
                    }).catch()
                } else {
                    // this.$message({
                    //     message: '提交失败，请稍后重试',
                    //     type: 'warning'
                    // });
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        changeContent(item){
            this.text=item.caseTitle2
            item.caseTitle2=item.caseContent
            // console.log(item);
        },
        recoveryContent(item){
            console.log(this.text);
            item.caseTitle2=this.text
            // console.log(item);
        }
        // gotoWeiyi(){

        // }
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    min-width: 1200px;
    min-height: 300px;
    // height: 450px;
    height: 26vw;
    // height: 500px;
    background-image: url("../assets/image/haiwai/haiwaiBack.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: center;

    // div {
    //     margin: auto;
    //     padding-top: 80px;
    //     width: 1160px;
    //     color: #EA5514;
    //     text-align: center;
    // }

    // p {
    //     margin: 20px 0;
    //     color: #fff;
    //     font-size: 50px;
    //     font-weight: 700;
    // }

    // .english {
    //     display: block;
    //     font-size: 16px;
    //     color: #fff;
    // }

    button {
        position: absolute;
        // bottom: 40px;
        bottom: 4.3vw;
        // margin-top: 350px;
        // width: 202px;
        // height: 56px;
        width: 10.5vw;
        min-width: 126px;
        min-height: 35px;
        // min-width: 202px;
        // height: 3vw;
        padding: 10px;
        // line-height: 30px;
        line-height: 1.6vw;
        font-weight: 500;
        // font-size: 26px;
        font-size: 24px;
        // font-size: 1.4vw;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        // border-radius: 30px;
        border-radius: 30px;

        a {
            color: #FFFFFF;

        }
    }
}

.kuaijiQ {
    margin: 60px auto;
    display: flex;
    justify-content: space-around;
    width: 1200px;

    img {
        border-radius: 0 !important;
    }

    div {
        margin-right: 20px;

        >p {
            margin-bottom: 10px;
            font-size: 22px;
            font-weight: 600;
        }

        .kuaijiQC {
            margin: 20px 0;
            font-size: 18px;
            line-height: 25px;
            font-weight: 400;
        }

        ul {
            margin-top: 50px;
            display: flex;
            justify-content: space-around;
            box-shadow: 0 0 10px 0 #F4F4F4;

            li {
                padding: 20px 30px;
                text-align: center;
                // border-right: 1px solid black;

                span {
                    display: block;
                    font-size: 14px;
                    color: #949494;
                }
            }
        }
    }

}

.kunnan {
    display: flex;
    justify-content: space-between;
    margin: 40px auto 0;
    width: 1200px;

    li {
        position: relative;
        width: 24%;
        height: 250px;
        background-image: url('../assets/image/haiwai/yuyanZ.png');
        background-size: 98% 95%;
        background-repeat: no-repeat;
        text-align: center;
        // overflow: hidden;

        // img {
        //     width: 100%;
        //     height: 300px;
        // }

        >p {
            // margin-top: 20px;
            position: absolute;
            left: 0px;
            bottom: 12px;
            padding: 15px;
            width: 87.3%;
            text-align: center;
            color: #fff;
            // background-color: rgba(0, 0, 0, 0.8);

            span {
                display: block;

                &:first-child {
                    font-size: 18px;
                }

                &:last-child {
                    font-size: 14px;
                }
            }
        }

        >div {
            display: none;
            color: #fff;
            text-align: center;

            p {
                margin-top: 100px;
            }

            >div {
                margin: 10px auto;
                width: 15%;
                height: 2px;
                background-color: #8FC31F;
                border-radius: 2px;
            }

            span {
                display: block;
                margin: auto;
                width: 60%;
                font-size: 14px;
            }
        }

        &:hover {
            background-image: url('../assets/image/haiwai/yuyanO.png');

            >p {
                display: none;
            }

            >div {
                display: block;
            }
        }

        &:nth-child(2) {
            background-image: url('../assets/image/haiwai/haiwaiZ.png');

            &:hover {
                background-image: url('../assets/image/haiwai/haiwaiO.png');

                >p {
                    display: none;
                }

                >div {
                    display: block;
                }
            }
        }

        &:nth-child(3) {
            background-image: url('../assets/image/haiwai/shijianZ.png');

            &:hover {
                background-image: url('../assets/image/haiwai/shijianO.png');

                >p {
                    display: none;
                }

                >div {
                    display: block;
                }
            }
        }

        &:nth-child(4) {
            background-image: url('../assets/image/haiwai/yinhangZ.png');

            &:hover {
                background-image: url('../assets/image/haiwai/yinhangO.png');

                >p {
                    display: none;
                }

                >div {
                    display: block;
                }
            }
        }
    }
}

.canIdo {
    // position: relative;
    padding: 30px 0;
    min-width: 1200px;
    background-color: #F4F4F4;
    // background-image: url('../assets/image/qidai/60f36944c6bf951d8579d623d7e627ac62513e922dccf6-6Npbt3_fw1200.png');

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    div {
        position: relative;
        display: flex;
        margin: 80px auto 50px;
        width: 1200px;

        img {
            margin: auto;
            // position: absolute;
            width: 400px;
            height: 400px;
            z-index: 9999;
        }

        ul {
            position: absolute;
            // display: flex;
            // justify-content: space-around;
            margin: 50px auto 30px;
            width: 28%;
            z-index: 0;

            li {
                // position: relative;
                margin-bottom: 20px;
                padding: 15px 25px 5px;
                // width: 10%;
                height: 90px;
                text-align: start;
                border: 2px solid #EA5514;
                background-color: #fff;
                border-radius: 10px;

                // overflow: hidden;
                p {
                    color: #727272;

                }

                span {
                    display: block;
                    width: 90%;

                    &:first-child {
                        margin-bottom: 10px;
                        padding: 3px 10px;
                        width: 35%;
                        text-align: center;
                        background-color: #EA5514;
                        font-size: 18px;
                        border-radius: 30px;
                        color: #fff;
                    }

                    &:last-child {
                        font-size: 14px;
                    }
                }
            }

            &:first-child {
                top: -10%;
                left: 10%;

                li {
                    &:first-child {
                        border-radius: 0;
                        border-bottom-left-radius: 50px;
                        border-top-right-radius: 50px;
                    }

                    &:last-child {
                        border-radius: 0;
                        border-top-left-radius: 50px;
                        border-bottom-right-radius: 50px;
                    }
                }
            }

            &:last-child {
                top: -10%;
                left: 63%;

                li {
                    text-align: end;
                    // p{
                    //     text-align: end;
                    // }

                    span {

                        margin-left: 25px;

                        &:first-child {
                            margin-left: 160px;
                        }

                    }

                    &:first-child {
                        border-radius: 0;
                        border-bottom-right-radius: 50px;
                        border-top-left-radius: 50px;
                    }

                    &:last-child {
                        // text-align: end;
                        border-radius: 0;
                        border-top-right-radius: 50px;
                        border-bottom-left-radius: 50px;

                        span {
                            &:last-child {
                                margin-left: 10px;
                                width: 95%;
                            }
                        }
                    }
                }

            }
        }
    }

}

.service {
    // position: relative;
    padding: 30px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    .top {
        display: flex;
        // justify-content: space-around;
        margin: 50px auto 0px;
        width: 1200px;

        .topLeft {
            margin-bottom: 17px;
            width: 288px;
            height: 281px;
            background-color: #fff;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            >div {
                margin-bottom: 20px;
                width: 50px;
                height: 50px;
                background-image: url('../assets/image/haiwai/haiwaiyinhangO.png');
                background-size: 100% 100%;
            }

            p {
                text-align: center;
                // font-weight: 600;
                font-weight: 500;
                font-size: 22px;
                color: #333333;
                line-height: 30px;
            }

            &:hover {
                background-image: url('../assets/image/qidai/bottomLine.png');
                background-size: 100% 5%;
                background-repeat: no-repeat;
                background-position: 0 100%;
            }
        }

        .topRight {
            display: flex;
            // justify-content: space-around;
            flex-wrap: wrap;
            // width: 75%;
            width: 912px;

            li {
                position: relative;
                // margin-bottom: 1%;
                margin-bottom: 17px;
                margin-left: 16px;
                padding: 40px 30px;
                display: flex;
                align-items: center;
                // width: 29%;
                // width: 25%;
                width: 228px;
                height: 52px;
                background-color: #fff;
                border-radius: 10px;
                cursor: pointer;
                // z-index: 9999;

                >div {
                    // position: absolute;
                    // top: 52px;
                    // left: 35px;
                    // margin: 30px 0 20px;
                    // margin-left: 100px;
                    width: 50px;
                    height: 50px;
                    background-image: url('../assets/image/haiwai/haiwaiyinhangO.png');
                    background-size: 100% 100%;
                }

                &:nth-child(2) {
                    >div {
                        background-image: url('../assets/image/haiwai/vieO.png');
                    }
                }

                &:nth-child(3) {
                    >div {
                        background-image: url('../assets/image/haiwai/waihuiO.png');
                    }
                }

                &:nth-child(4) {
                    >div {
                        background-image: url('../assets/image/haiwai/haiguanO.png');
                    }
                }

                &:nth-child(5) {
                    >div {
                        background-image: url('../assets/image/haiwai/guquanO.png');
                    }
                }

                &:nth-child(6) {
                    >div {
                        background-image: url('../assets/image/haiwai/VAT.png');
                    }
                }

                >span {
                    margin-left: 20px;
                    // margin-top: 50px;
                    display: block;

                    p {
                        width: 115%;
                        margin: 0 0 5px;
                        font-size: 19px;
                        // font-weight: 600;
                        font-weight: 500;
                    }

                    span {
                        font-size: 14px;
                        color: #B1B1B1;
                        font-weight: 400;
                    }
                }

                // .bottom {
                //     position: absolute;
                //     left: 0;
                //     bottom: 0;
                //     width: 100%;
                //     // z-index: 0;
                // }

                &:hover {
                    background-image: url('../assets/image/qidai/bottomLine.png');
                    background-size: 100% 10%;
                    background-repeat: no-repeat;
                    background-position: 0 100%;
                }

                .qizhi {
                    position: absolute;
                    top: -10px;
                    right: 40px;
                    margin: 10px 0;
                    width: 35px;
                    height: 50px;
                    color: #F2956C;
                    text-align: center;
                    line-height: 40px;
                    background-image: url('../assets/image/zizhi/qizhi.png');
                    background-size: 100% 100%;
                }
            }
        }
    }

    .bottom {
        display: flex;
        // justify-content: space-around;
        margin: auto;
        width: 1200px;

        li {
            position: relative;
            // margin-bottom: 1%;
            margin-bottom: 17px;
            margin-left: 16px;
            padding: 40px 30px;
            display: flex;
            align-items: center;
            // width: 29%;
            // width: 25%;
            width: 228px;
            height: 52px;
            background-color: #fff;
            border-radius: 10px;
            cursor: pointer;
            // z-index: 9999;

            >div {
                // position: absolute;
                // top: 52px;
                // left: 35px;
                // margin: 30px 0 20px;
                // margin-left: 100px;
                width: 50px;
                height: 50px;
                // background-image: url('../assets/image/haiwai/haiwaiyinhangO.png');
                background-size: 100% 100%;
            }

            &:first-child {
                margin-left: 0;

                >div {
                    background-image: url('../assets/image/haiwai/chukoutuishui.png');
                }
            }

            &:nth-child(2) {
                >div {
                    background-image: url('../assets/image/haiwai/quanqiu.png');
                }
            }

            &:nth-child(3) {
                >div {
                    background-image: url('../assets/image/haiwai/geguogongshang.png');
                }
            }

            &:nth-child(4) {
                >div {
                    background-image: url('../assets/image/haiwai/ERP.png');
                }
            }

            >span {
                margin-left: 20px;
                // margin-top: 50px;
                display: block;
                width: 160px;

                p {
                    width: 115%;
                    margin: 0 0 5px;
                    font-size: 20px;
                    // font-weight: 600;
                    font-weight: 500;
                }

                span {
                    font-size: 14px;
                    color: #B1B1B1;
                    font-weight: 400;
                }
            }

            // .bottom {
            //     position: absolute;
            //     left: 0;
            //     bottom: 0;
            //     width: 100%;
            //     // z-index: 0;
            // }

            &:hover {
                background-image: url('../assets/image/qidai/bottomLine.png');
                background-size: 100% 10%;
                background-repeat: no-repeat;
                background-position: 0 100%;
            }

            .qizhi {
                position: absolute;
                top: -10px;
                right: 40px;
                margin: 10px 0;
                width: 35px;
                height: 50px;
                color: #F2956C;
                text-align: center;
                line-height: 40px;
                background-image: url('../assets/image/zizhi/qizhi.png');
                background-size: 100% 100%;
            }
        }
    }

    button {
        margin: 60px 0 75px;
        // margin-left: 46%;
        // margin-left: 45%;
        // min-width: 8%;
        width: 202px;
        height: 56px;
        padding: 5px 15px;
        // font-size: 26px;
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        // background-color: #FE8F3F;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;
        text-align: center;
    }

}

.personal {
    // position: relative;
    padding: 75px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        margin: 30px auto 0;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        width: 1200px;

        li {
            position: relative;
            margin-top: 1vh;
            margin-left: 10px;
            // width: 24%;
            // height: 340px;
            width: 289px;
            height: 356px;
            // background-image: url("../assets/image/personal.png");
            background-size: 100% 100%;
            // border-radius: 0;
            border-radius: 6px;
            overflow: hidden;

            // overflow: hidden;
            >p {
                margin: 0;
                position: absolute;
                bottom: 0;
                padding: 14px;
                width: 90%;
                background-color: rgba(0, 0, 0, 0.5);
                text-align: start;
                // border-radius: 0;
                color: #fff;
            }

            .personalT {
                display: block;
                font-size: 18px;
                font-weight: 600;
            }

            .personalC {
                font-size: 16px;
                font-weight: 500;
            }
        }
    }

    button {
        margin-top: 20px;
        margin-left: 48%;
        padding: 2px 8px;
        color: #9B9B9B;
        border: 1px solid#9B9B9B;
    }
}

.flow {
    // position: relative;
    padding: 30px 0 50px;
    background-image: url("../assets/image/kuaiji/6458486361c124a42770f394d12bef7d526a503611913d-0S20U5_fw1200.png");

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >ul {
        margin: 30px auto 20px;
        // padding: 0 0 20px;
        display: flex;
        justify-content: space-around;
        // flex-wrap: wrap;
        width: 1200px;
        // height: 200px;
        // background-image: url("../assets/image/qidai/line.png");
        // background-size: 100% 50%;
        // background-repeat: no-repeat;

        li {
            position: relative;
            // width: 4%;
            width: 45px;
            height: 200px;
            // text-align: center;
            color: #EA5514;
            border: 1px solid #FBAD8B;
            border-radius: 4px;

            div {
                position: absolute;
                top: -52px;
                left: 14px;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background-color: #F2D5C8;
            }

            p {
                margin: 10px auto;
                width: 30px;
                height: 30px;
                font-size: 18px;
                text-align: center;
                line-height: 30px;
                background-color: #FFF4EE;
                // color: #EA5514;
                border-radius: 50%;
            }

            span {
                // display: block;
                line-height: 45px;
                writing-mode: vertical-rl;
                // text-align: center;
            }

            &:hover {
                background-color: #F6EBE5;
                border: 1px solid #F6EBE5;

                div {
                    background-color: #EA5514;
                }

                p {
                    background-color: #EA5514;
                    color: #fff;
                }
            }

            &:nth-child(6) {
                width: 60px;

                div {
                    left: 20px;
                }

                span {
                    margin-left: 7px;
                    line-height: 23.7px;
                }
            }
        }
    }
}

.advantages {
    // position: relative;
    padding: 75px 0 30px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        display: flex;
        margin: 30px auto;
        width: 1200px;

        // justify-content: space-around;
        img {
            width: 50%;
        }

        ul {
            margin-top: 55px;
            margin-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 50%;
            height: 340px;

            li {
                display: flex;
                margin-top: 20px;
                padding: 8px 15px;
                width: 90%;
                background-color: #fff;
                border-radius: 6px;

                div {
                    width: 30px;
                    height: 30px;
                    background-image: url('../assets/image/kuaiji/quan.png');
                    background-size: 100% 100%;
                    text-align: center;
                    line-height: 30px;
                    color: #fff;
                    font-weight: 600;
                }

                p {
                    margin-left: 20px;
                    line-height: 28px;
                    font-size: 20px;
                }

                &:hover {
                    margin-left: -5%;
                    width: 95%;
                    background-color: #EA5514;
                    color: #fff;

                    div {
                        background-image: url('../assets/image/kuaiji/quanH.png');
                        color: #EA5514;
                    }
                }
            }
        }
    }

    button {
        margin-left: 48%;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        background-color: #FE8F3F;
        border: 0;
        border-radius: 20px;
    }
}

.success {
    // position: relative;
    padding: 30px 0 10px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 30px auto;
        width: 1200px;
        // height: 417px;
        height: 400px;
        overflow: hidden;
        // width: 50%;

        li {
            // display: flex;
            // margin-bottom: 25px;
            padding: 20px 15px;
            width: 30%;
            height: 360px;
            background-color: #fff;
            border-radius: 6px;

            >img {
                width: 100%;
                height: 200px;
            }

            >div {
                position: relative;
                margin-left: 10px;
                // position: relative;
                text-align: start;

                // width: 500px;
                p {
                    margin-top: 10px;
                    text-align: start;
                    font-size: 16px;
                    font-weight: 600;
                }

                span {
                    display: block;
                    margin-top: 10px;
                    font-size: 14px;
                    line-height: 25px;
                    color: #AFAFAF;
                    // display: -webkit-box;
                    // -webkit-box-orient: vertical;
                    // -webkit-line-clamp: 3;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                }

                button {
                    margin-top: 5px;
                    padding: 2px 5px;
                    border: 0;
                    border-radius: 2px;
                    background-color: #E6F2CC;
                    color: #A4C361;
                }

                .gengduo {
                    position: absolute;
                    right: 0px;
                    top: 10px;
                    width: 20px;
                    height: 4px;
                    background-image: url('../assets/image/gengduo.png');
                    background-size: 100% 100%;
                }
            }

            &:hover {
                width: 100%;
                // height: 357px;
                height: 360px;
                color: #fff;
                background-color: #E65413;

                img {
                    height: 0;
                }

                div {
                    padding: 0 20px 0 0;

                    p {
                        margin: 20px 0;
                    }

                    span {
                        color: #fff;
                        display: block;
                        line-height: 24px;
                    }

                    button {
                        margin: 0px 0 10px;
                        color: #fff;
                        background-color: #EE824E;
                    }
                }

                .gengduo {
                    display: none;
                }
            }

            &:nth-child(2) {
                &:hover {
                    position: relative;
                    // top: -380px;
                    top: -400px;
                    // top: -390px;
                    // top: -385px;
                }
            }

            &:last-child {
                &:hover {
                    position: relative;
                    top: -400px;
                }
            }
        }
    }

    >button {
        margin-left: 48%;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        background-color: #FE8F3F;
        border: 0;
        border-radius: 20px;
    }
}

.offer {
    // position: relative;
    padding: 30px 0 30px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        display: flex;
        justify-content: space-around;
        margin: 10px auto 40px;
        width: 1200px;

        li {
            // padding: 20px;
            width: 24%;
            background-color: #fff;
            border-radius: 6px;
            text-align: center;

            p {
                margin: auto;
                padding: 40px 40px 20px;
                width: 40%;
                text-align: center;
                border-bottom: 1px dashed #E1E1E1;

                img {
                    // margin: auto;
                    width: 50px;
                    height: 50px;
                }

                span {
                    display: block;
                    margin-top: 10px;
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            button {
                margin: 20px 0;
                padding: 5px 15px;
                color: #fff;
                background-color: #EA5514;
                border-radius: 15px;
                border: 0;
            }

            &:nth-child(1) {
                img {
                    width: 58px;
                    height: 58px;
                }

                p {
                    padding-top: 38px;

                    span {
                        margin-top: 5px;
                    }
                }
            }

            &:nth-child(3) {
                img {
                    width: 70px;
                    height: 65px;
                }

                p {
                    padding-top: 30px;

                    span {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

.orange {
    padding: 70px 0;
    width: 100%;
    min-width: 1200px;
    // height: 140px;
    background-image: url("../assets/image/橙色背景.png");
    background-size: 100% 100%;
    text-align: center;
    line-height: 90px;
    color: #fff;
    font-size: 40px;

    img {
        position: relative;
        top: -10px;

        &:nth-child(2) {
            transform: rotate(180deg) !important;
        }
    }

    // line-height: 150px;
    // span {
    //     display: block;
    //     margin-top: 20px;
    //     font-size: 16px;

    //     // line-height: 10px;

    // }
}

.btn {
    padding-bottom: 50px;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    background-color: #F4F4F4;

    button {
        // margin-top: 350px;
        width: 202px;
        height: 56px;
        line-height: 30px;
        font-weight: 500;
        font-size: 26px;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;

        a {
            color: #FFFFFF;

        }
    }
}

.info {
    margin: auto;
    position: relative;
    width: 350px;
    border-radius: 6px;
    text-align: center;
    overflow: hidden;
    background-color: #fff;

    p {
        padding: 20px 40px 40px;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        background-color: #EA5513;
    }

    .name {
        position: absolute;
        top: 60px;
        left: 140px;
        width: 50px;
        height: 50px;
    }

    .cheng {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 25px;
        height: 25px;

    }

    >img {
        &:last-child {
            margin: 40px 0 20px;
            width: 70%;
        }

    }
}

::v-deep .el-dialog--center .el-dialog__body {
    padding-bottom: 0;
}

::v-deep .el-form {
    margin-top: 30px;
    margin-left: -20px;

    .el-form-item {
        .el-form-item__label {
            width: 70px !important;
        }

        .el-form-item__content {
            margin-left: 70px !important;
        }
    }
}

::v-deep .el-textarea__inner {
    height: 150px !important;
}
</style>