<template>
  <div id="app">
    <top-view :type="type"></top-view>
    <div class="line"></div>
    <div class="content">
      <h2>{{ content.dynamicTitle }}</h2>
      <!-- <img :src="imgurl + content.dynamicImg" alt=""> -->
      <div class="contentImg" v-html="content.dynamicContent"></div>
    </div>
    <footer-view></footer-view>
    <side-bar class="fixed"></side-bar>
  </div>
</template>

<script>
import TopView from '@/components/topView.vue'
import FooterView from '@/components/footerView.vue'
import SideBar from '@/components/sideBar.vue'
import axios from 'axios';
export default {
  components: {
    TopView,
    SideBar,
    FooterView,
    SideBar
  },
  data() {
    return {
      content: {},
      // imgurl:'http://192.168.0.199:8087/api'
      imgurl: '',
      type:''
    }
  },
  created() {
    this.getqydtC()
    this.website()
    this.imgurl = this.$store.state.imgurl
    this.type = this.$route.query.type
    // this.$store.state.componentKey += 1
  },
  methods: {
    getqydtC() {
      // console.log("ssssssssssss");
      const qydtid = this.$route.query.id
      // console.log(qydtid);
      axios.get(`/api/pcsystem/TckgDynamic/${qydtid}`).then((res) => {
        if (res.status == 200) {
          console.log(res);
          this.content = res.data.data
        }

      }).catch()
    },
    //网站访问信息
    website() {
      axios.post('/api/pcsystem/TckgSoftLogin', { userType: '详情' }).then((res) => {
        console.log(res);
      }).catch()
    },
  },
  mounted() {
  }

}
</script>

<style lang="scss" scoped>
.fixed {
  position: fixed;
  top: 150px;
  right: 5px;
}

.line {
  margin-top: -15px;
  width: 100%;
  height: 10px;
  // background-color: #E7E7E7;
  // box-shadow: 0px 2px 5px 0px rgba(202,202,202,0.5);
  background: linear-gradient(to bottom, #E7E7E7, #fff);
}

.content {
  min-height: 700px;
  // box-shadow: 0 0 10 0 rgba(0, 0, 0, 0.4);
  margin: 30px auto;
  text-align: center;
  width: 1200px;

  h2 {
    margin: 30px 0 10px;
  }

  img {
    width: 90%;
    height: 400px;
    border-radius: 6px;
  }

  >div {
    margin: 20px auto;
    width: 90%;
    text-align: left;
    line-height: 30px;
  }
}
</style>
<style>
.contentImg p img {
  width: 1080px;
}
</style>