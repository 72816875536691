<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
            <!-- <div>
                <p>会计事业群</p>
                <span class="english">ACCOUNTING BUSINESS GROUP</span>
                <button @click="gotoAboutus">了解更多</button>
            </div> -->
        </div>
        <div class="kuaijiQ">
            <div>
                <p>会计事业群</p>
                <div
                    style="margin-bottom: 40px;width: 81px;height: 4px;background: linear-gradient( 270deg, #F4F4F4 0%, #EA5514 100%);border-radius: 1px;">
                </div>
                <p class="kuaijiQC">
                    唐诚旗下支柱性产业群，主要服务中国境内中小微企业。业务涵盖公司注册、地址托管、记账报税、行政审批等。
                </p>
                <p class="kuaijiQC">
                    目前，在全国服务企业5万+，在北京、上海、深圳等十二个城市设有分公司，全国拥有专业会计服务人员758人，其中注册会计师24人，注册税务师15人，中高级会计师120人。
                </p>
                <ul>
                    <li>
                        <p class="num">50000<span style="font-size: 14px;color: black;">+</span></p>
                        <span>服务企业</span>
                    </li>
                    <li></li>
                    <li>
                        <p class="num">758<span style="font-size: 14px;color: black;font-weight: 500;">人</span></p>
                        <span>服务人员</span>
                    </li>
                    <li></li>
                    <li>
                        <p class="num">12<span style="font-size: 14px;color: black;font-weight: 500;">个</span></p>
                        <span>城市分布</span>
                    </li>
                </ul>
            </div>
            <img src="../assets/image/kuaiji/kuaijishiyequn.png">
        </div>
        <div class="canIdo">
            <p><img src="../assets/image/left.png">&nbsp;我们可以为您的企业做什么&nbsp;<img src="../assets/image/right.png"></p>
            <span>WHAT CAN WE DO FOR YOUR BUSINESS</span>
            <div>
                <ul>
                    <li>
                        <p>税务咨询</p>
                        <span>结合企业经营情况，在税务相关事务方面给予专业意见</span>
                    </li>
                    <li>
                        <p>纳税评估</p>
                        <span>通过评估发现征收管理过程中的不足，强化管理监控功能</span>
                    </li>
                    <li>
                        <p>风险测评</p>
                        <span>快速诊断企业财税风险，并生成风险对应方案</span>
                    </li>
                </ul>
                <img src="../assets/image/kuaiji/canIdo.png">
                <ul>
                    <li>
                        <p>减少补税</p>
                        <span>整理所需材料，与税务机关沟通，达到最少补税金额</span>
                    </li>
                    <li>
                        <p>税务复议</p>
                        <span>对税务机关决定不服，向其提起复议</span>
                    </li>
                    <li>
                        <p>提升合规性</p>
                        <span>解决财务专业性不足或财务人员问题，促进专业合规</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="service">
            <p><img src="../assets/image/left.png">&nbsp;服务内容&nbsp;<img src="../assets/image/right.png"></p>
            <span>SERVICE CONTENT</span>
            <ul>
                <li>
                    <div></div>
                    <!-- <img src="../assets/image/kuaiji/zhuceO.png"> -->
                    <p>公司注册/注销</p>
                    <span>专人全程跟进</span>
                </li>
                <li>
                    <div></div>
                    <!-- <img src="../assets/image/kuaiji/biangengO.png"> -->
                    <p>公司变更</p>
                    <span>快速高效办理</span>
                </li>
                <li>
                    <div></div>
                    <!-- <img src="../assets/image/kuaiji/yichangO.png"> -->
                    <p>解决异常</p>
                    <span>解决公司疑难问题</span>
                </li>
                <li>
                    <div></div>
                    <!-- <img src="../assets/image/kuaiji/jizhangO.png"> -->
                    <p>代理记账</p>
                    <span>服务贴心，省时省力</span>
                </li>
                <li>
                    <div></div>
                    <!-- <img src="../assets/image/kuaiji/shenjiO.png"> -->
                    <p>审计报告</p>
                    <span>专业化、规模化、权威化</span>
                </li>
                <li>
                    <div></div>
                    <!-- <img src="../assets/image/kuaiji/shougouO.png"> -->
                    <p>公司收购/转让</p>
                    <span>签署正规协议，安全可靠</span>
                </li>
            </ul>
            <div style="display: flex;justify-content: center;"><button @click="infoShow = true">点击咨询</button></div>
        </div>
        <div class="orange">

            <span><img src="../assets/image/kuaiji/whiteLine.png">&nbsp;&nbsp;低成本解决当前企业遇到问题，一户一服务方案&nbsp;&nbsp;<img
                    src="../assets/image/kuaiji/whiteLine.png"></span>
        </div>
        <div class="personal">
            <p><img src="../assets/image/left.png">&nbsp;资深专家&nbsp;<img src="../assets/image/right.png"></p>
            <span>SENIOR EXPERTS</span>
            <ul>
                <li v-for="item in personalList" :key="item.personnelId">
                    <img :src="imgurl + item.personnelImg" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">{{ item.personnelName }}</span>
                        <span class="personalC">{{ item.personnelPosition }}</span>
                    </p>
                </li>
                <!-- <li>
                    <img src="../assets/image/kuaiji/personal8.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">肖艳</span>
                        <span class="personalC">唐诚会计事业群总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/kuaiji/personal3.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">梁麒</span>
                        <span class="personalC">唐诚北京会计事业一部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/kuaiji/personal4.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">臧邵鑫</span>
                        <span class="personalC">唐诚北京会计事业二部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/kuaiji/personal2.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">杜绿园</span>
                        <span class="personalC">唐诚广州会计事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/kuaiji/personal1.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">罗田田</span>
                        <span class="personalC">唐诚深圳会计事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/kuaiji/personal5.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">岳宇</span>
                        <span class="personalC">唐诚成都会计事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/kuaiji/personal6.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">黄晨霞</span>
                        <span class="personalC">唐诚西安会计事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/kuaiji/personal7.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">刘青青</span>
                        <span class="personalC">唐诚石家庄会计事业部总经理</span>
                    </p>
                </li> -->
                <!-- <li v-if="show">
                    <img src="../assets/image/kuaiji/personal11.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">梅海静</span>
                        <span class="personalC">唐诚廊坊会计事业部总经理</span>
                    </p>
                </li>
                <li v-if="show">
                    <img src="../assets/image/kuaiji/personal9.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">刘微</span>
                        <span class="personalC">唐诚武汉会计事业部总经理</span>
                    </p>
                </li>
                <li v-if="show">
                    <img src="../assets/image/kuaiji/personal10.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">赵静</span>
                        <span class="personalC">唐诚杭州会计事业部总经理</span>
                    </p>
                </li> -->
            </ul>
            <button @click="isShow()" class="btn">
                <p v-if="!show">展开<img src="../assets/image/kuaiji/zhankai.png"></p>
                <p v-else>收起<img src="../assets/image/kuaiji/shouqi.png"></p>
            </button>
        </div>
        <div class="flow">
            <p><img src="../assets/image/left.png">&nbsp;服务流程&nbsp;<img src="../assets/image/right.png"></p>
            <span>SERVICE FLOW</span>
            <ul>
                <li>
                    <img src="../assets/image/kuaiji/jiantou.png">
                    <p>01.</p>
                    <span>了解基本信息</span>
                </li>
                <li>
                    <img src="../assets/image/kuaiji/jiantou.png">
                    <p>02.</p>
                    <span>签订合同</span>
                </li>
                <li>
                    <img src="../assets/image/kuaiji/jiantou.png">
                    <p>03.</p>
                    <span>求匹配专业的会计服务团队</span>
                </li>
                <li>
                    <img src="../assets/image/kuaiji/jiantou.png">
                    <p>04.</p>
                    <span>办理税务登记实名采集、银行三方协议的签订</span>
                </li>
                <li>
                    <img src="../assets/image/kuaiji/jiantou.png">
                    <p>05.</p>
                    <span>根据需求办理审批、领取发票</span>
                </li>
                <li>
                    <img src="../assets/image/kuaiji/jiantou.png">
                    <p>10.</p>
                    <span>提供增值服务</span>
                </li>
                <li>
                    <img src="../assets/image/kuaiji/jiantou.png">
                    <p>09.</p>
                    <span>客服专员定期回访</span>
                </li>
                <li>
                    <img src="../assets/image/kuaiji/jiantou.png">
                    <p>08.</p>
                    <span>按月出具财务报表</span>
                </li>
                <li>
                    <img src="../assets/image/kuaiji/jiantou.png">
                    <p>07.</p>
                    <span>按月提供记账、报税、税款缴纳等服务</span>
                </li>
                <li>
                    <img src="../assets/image/kuaiji/jiantou.png">
                    <p>06.</p>
                    <span>按月上门取送发票和资料</span>
                </li>
            </ul>
        </div>
        <div class="advantages">
            <p><img src="../assets/image/left.png">&nbsp;服务优势&nbsp;<img src="../assets/image/right.png"></p>
            <span>SERVICE ADVANTAGES</span>
            <div>
                <img src="../assets/image/kuaiji/woshou.png">
                <ul>
                    <li>
                        <div>01</div>
                        <p>专业性强，实践经验丰富</p>
                    </li>
                    <li>
                        <div>02</div>
                        <p>行业涉猎范围广</p>
                    </li>
                    <li>
                        <div>03</div>
                        <p>及时预见市场趋势，提供前瞻性服务</p>
                    </li>
                    <li>
                        <div>04</div>
                        <p>培训体系完善，有一支专业化的队伍</p>
                    </li>
                </ul>
            </div>
            <div style="display: flex;justify-content: center;"><button @click="infoShow = true">点击咨询</button></div>
        </div>
        <div class="success">
            <p><img src="../assets/image/left.png">&nbsp;成功案例&nbsp;<img src="../assets/image/right.png"></p>
            <span>SUCCESS STORIES</span>
            <div>
                <ul>
                    <li v-for="item in caseList" :key="item.caseId" @mouseenter="changeContent(item)" @mouseleave="recoveryContent(item)">
                        <div class="img"></div>
                        <div>
                            <p>项目：{{ item.caseTitle }}<button>耗时：{{ item.caseHs }}</button></p>
                            <span v-html="item.caseTitle2"></span>
                            <!-- <button>耗时：{{ item.caseHs }}</button> -->
                        </div>
                        <div class="gengduo"></div>
                    </li>
                    <!-- <li>
                        <div class="img"></div>
                        <div>
                            <p>项目：财务外包</p>
                            <span>客户一直为公司的财务管理感到头疼。由于业务迅速扩张，财务管理变得越来越复杂，就决定寻求专业的财务外包服务。经过多方比较，他选择了唐诚，因为他发现唐诚不仅公司规模大，而且非常专业、负责人且服务态度极好。

                                在初次接触中，会计顾问先初步介绍了他们的工作理念，这让客户对唐诚的专业性有了更深的了解。随后，细心解答了他的各种疑问，并针对具体需求提供了解决方案。最终，客户决定与唐诚签订财务外包合同，并持续在唐诚进行业务合作。他对唐诚的专业度、服务态度以及解决问题的能力给予了高度评价，认为唐诚真正帮助他解决了财务管理上的难题。</span>
                            <button>耗时：10天</button>
                        </div>
                    </li>
                    <li>
                        <div class="img"></div>
                        <div>
                            <p>项目：财务外包</p>
                            <span>客户一直为公司的财务管理感到头疼。由于业务迅速扩张，财务管理变得越来越复杂，就决定寻求专业的财务外包服务。经过多方比较，他选择了唐诚，因为他发现唐诚不仅公司规模大，而且非常专业、负责人且服务态度极好。

                                在初次接触中，会计顾问先初步介绍了他们的工作理念，这让客户对唐诚的专业性有了更深的了解。随后，细心解答了他的各种疑问，并针对具体需求提供了解决方案。最终，客户决定与唐诚签订财务外包合同，并持续在唐诚进行业务合作。他对唐诚的专业度、服务态度以及解决问题的能力给予了高度评价，认为唐诚真正帮助他解决了财务管理上的难题。</span>
                            <button>耗时：10天</button>
                        </div>
                    </li>
                    <li>
                        <div class="img"></div>
                        <div>
                            <p>项目：财务外包</p>
                            <span>客户一直为公司的财务管理感到头疼。由于业务迅速扩张，财务管理变得越来越复杂，就决定寻求专业的财务外包服务。经过多方比较，他选择了唐诚，因为他发现唐诚不仅公司规模大，而且非常专业、负责人且服务态度极好。

                                在初次接触中，会计顾问先初步介绍了他们的工作理念，这让客户对唐诚的专业性有了更深的了解。随后，细心解答了他的各种疑问，并针对具体需求提供了解决方案。最终，客户决定与唐诚签订财务外包合同，并持续在唐诚进行业务合作。他对唐诚的专业度、服务态度以及解决问题的能力给予了高度评价，认为唐诚真正帮助他解决了财务管理上的难题。</span>
                            <button>耗时：10天</button>
                        </div>
                    </li> -->
                </ul>
                <img src="../assets/image/kuaiji/a5d49088970fab9672c7d45a1976b659993fe925b76e0-6MpW8n.png">
            </div>
        </div>
        <div class="offer">
            <p><img src="../assets/image/left.png">&nbsp;我们还提供这些服务&nbsp;<img src="../assets/image/right.png"></p>
            <span>WE ALSO OFFER THESE SERVICES</span>
            <ul>
                <li>
                    <p>
                        <img src="../assets/image/kuaiji/jiangpai.png">
                        <span>建筑资质</span>
                    </p>
                    <button @click="gotoZizhi">了解更多</button>
                </li>
                <li>
                    <p>
                        <img src="../assets/image/kuaiji/qianbao.png">
                        <span>金融服务</span>
                    </p>
                    <button @click="gotoQidai">了解更多</button>
                </li>
                <li>
                    <p>
                        <img src="../assets/image/kuaiji/haiwai.png">
                        <span>海外业务</span>
                    </p>
                    <button @click="gotoHaiwai">了解更多</button>
                </li>
                <li>
                    <p>
                        <img src="../assets/image/kuaiji/zhishi.png">
                        <span>知识产权</span>
                    </p>
                    <button @click="gotoZonghe">了解更多</button>
                </li>
            </ul>
        </div>
        <div class="orange">
            <span>唐诚控股&nbsp;实力保障</span>
            <span class="mini"><img
                    src="../assets/image/kuaiji/whiteLine.png">&nbsp;&nbsp;低成本解决当前企业遇到问题，一户一服务方案&nbsp;&nbsp;<img
                    src="../assets/image/kuaiji/whiteLine.png"></span>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>
        <!-- <div v-if="infoShow"
            style="padding-top: 200px;width:100vw;height: 100vh;background-color:rgba(0, 0, 0, 0.4);position: fixed;top: 0">
            <div class="info">
                <p>扫码关注微信公众号</p>
                <img src="../assets/icon/cheng.png" class="cheng" @click="infoShow = false">
                <img src="../assets/image/name.png" class="name">
                <img src="../assets/image/erweima.png">
            </div>
        </div> -->

        <el-dialog title="请填写您的个人信息" :visible.sync="infoShow" width="30%" center>
            <span
                style="font-size: 12px;color: #A5A5A5;text-align: center;display: block;margin-top: -30px;">我们会在30分钟内联系您</span>

            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入您的姓名"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="tel">
                    <el-input v-model="ruleForm.tel" placeholder="请输入您的电话号码"></el-input>
                </el-form-item>
                <el-form-item label="留言" prop="content">
                    <el-input v-model="ruleForm.content" type="textarea" placeholder="请输入您的留言（不超过300字）"
                        class="inputC"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm('ruleForm')">立即提交</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import topView from '@/components/topView.vue'
import footerView from '@/components/footerView.vue'
import SideBar from '@/components/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            show: false,
            infoShow: false,
            personalList: [],
            params: { pageSize: 8, pageNum: 1, personnelType: "5" },
            // imgurl: 'http://192.168.0.199:8087/api',
            imgurl: '',
            caseList: [],
            ruleForm: {
                name: '',
                tel: '',
                content: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' },
                    // { min: 7, max: 11, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                tel: [
                    { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                content: [
                    // { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { min: 0, max: 300, message: '最多输入300字', trigger: 'blur' }
                ]

            },
            text:'',
        }
    },
    created() {
        this.getPersonalList()
        this.getcaseList()
        this.website()
        this.imgurl = this.$store.state.imgurl
        // this.$store.state.componentKey += 1
    },
    methods: {
        isShow() {
            this.show = !this.show
            if (!this.show) {
                this.params = { pageSize: 8, pageNum: 1, personnelType: "5" },
                    this.getPersonalList()
            } else {
                this.params = { pageSize: 11, pageNum: 1, personnelType: "5" },
                    this.getPersonalList()
            }
        },
        getPersonalList() {
            axios.get('/api/pcsystem/TckgPersonnel/list', { params: this.params }).then((res) => {
                if (res.status == 200) {
                    this.personalList = res.data.rows
                    // console.log(this.personalList);
                }
            }).catch()
        },
        getcaseList() {
            axios.get('/api/pcsystem/TckgCase/list', { params: { pageNum: 1, pageSize: 3, caseType: '5' } }).then((res) => {
                if (res.status == 200) {
                    this.caseList = res.data.rows
                    // console.log(this.caseList);
                }
            }).catch()
        },
        gotoAboutus() {
            this.$router.push('/aboutus')
        },
        gotoQidai() {
            this.$router.push({ path: '/qidai', query: { key: '2' } })
        },
        gotoZonghe() {
            this.$router.push({ path: '/zonghe', query: { key: '2' } })
        },
        gotoHaiwai() {
            this.$router.push({ path: '/haiwai', query: { key: '3' } })
        },
        gotoZizhi() {
            this.$router.push({ path: '/zizhi', query: { key: '2' } })
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '会计事业群' }).then((res) => {
                console.log(res);
            }).catch()
        },
        //提交信息
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    this.infoShow = false
                    console.log(this.ruleForm);
                    axios.post('/api/pcsystem/TckgConsult', { params: this.ruleForm }).then((res) => {
                        // console.log(res);
                        if (res.status == 200) {
                            // console.log(111111);
                            this.$message({
                                message: '提交成功，请耐心等待',
                                type: 'success'
                            });
                        }
                    }).catch()
                } else {
                    // this.$message({
                    //     message: '提交失败，请稍后重试',
                    //     type: 'warning'
                    // });
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        changeContent(item){
            this.text=item.caseTitle2
            item.caseTitle2=item.caseContent
            // console.log(item);
        },
        recoveryContent(item){
            console.log(this.text);
            item.caseTitle2=this.text
            // console.log(item);
        }
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    // height: 500px;
    // height: 450px;
    min-width: 1200px;
    min-height: 310px;
    background-image: url("../assets/image/kuaijiBack.png");
    background-size: 100% 100%;

    // div {
    //     margin: auto;
    //     padding-top: 80px;
    //     width: 1160px;
    //     color: #EA5514;
    //     text-align: center;
    // }

    // p {
    //     margin-bottom: 10px;
    //     color: #fff;
    //     font-size: 50px;
    //     font-weight: 700;
    // }

    // .english {
    //     display: block;
    //     font-size: 16px;
    //     color: #fff;
    // }

    // button {
    //     margin-top: 20px;
    //     width: 100px;
    //     height: 30px;
    //     line-height: 30px;
    //     color: #fff;
    //     background-color: #FFB258;
    //     border: 0;
    //     border-radius: 15px;
    // }
}

.kuaijiQ {
    margin: 60px auto;
    display: flex;
    justify-content: space-around;
    width: 1200px;

    img {
        border-radius: 0 !important;
    }

    div {
        margin-right: 20px;

        >p {
            margin-bottom: 10px;
            font-size: 22px;
            font-weight: 600;
        }

        .kuaijiQC {
            margin: 20px 0;
            font-size: 18px;
            line-height: 25px;
            font-weight: 400;
        }

        ul {
            margin-top: 50px;
            display: flex;
            justify-content: space-evenly;
            box-shadow: 0 0 10px 0 #F4F4F4;

            li {
                padding: 20px 30px;
                text-align: center;
                // border-right: 1px solid black;

                p {
                    font-size: 30px;
                    font-weight: 600;
                    color: #8FC31F;
                }

                &:nth-child(2),
                &:nth-child(4) {
                    margin-top: 35px;
                    padding: 0;
                    width: 1px;
                    height: 26px;
                    // border: 1px solid #C8C4C4;
                    background-color: #C8C4C4;
                }
            }
        }
    }

}

.canIdo {
    // position: relative;
    padding: 30px 0;
    padding-top: 60px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        margin: 50px auto;
        display: flex;
        justify-content: space-around;
        width: 1160px;

        img {
            width: 360px;
            height: 360px;
        }

        ul {
            li {
                margin-bottom: 50px;
                width: 230px;

                // width: 260px;
                p {
                    margin: 0 0 10px;
                    // padding: 5px 10px;
                    // width: 100px;
                    padding: 3px 0;
                    width: 158px;
                    color: #fff;
                    text-align: center;
                    border-radius: 0px 20px 0px 20px;
                    // border-bottom-left-radius: 60%;
                    // border-top-right-radius: 60%;
                    background-color: #EA5514;
                    font-size: 24px;
                    font-weight: 500;
                }

                span {
                    font-size: 16px;
                    font-weight: 400;
                    color: #767676;
                }
            }

            &:last-child {
                // display: flex;
                // flex-direction: column;
                // justify-content: end;
                // align-items: end;
                text-align: end;

                li {

                    // position: relative;
                    p {
                        margin-left: 70px;
                        // margin-left: 100px;
                        // position: absolute;
                        // right: 0;
                    }

                    // right: 0;
                }
            }
        }
    }

}

.service {
    // position: relative;
    padding: 30px 0;
    padding-top: 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 40px auto 20px;
        width: 1160px;

        li {
            margin-bottom: 1%;
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 32.5%;
            background-color: #fff;
            border-radius: 6px;
            cursor: pointer;

            >div {
                margin: 30px 0 10px;
                width: 70px;
                height: 80px;
                background-image: url('../assets/image/kuaiji/zhuceO.png');
                background-size: 100% 100%;
            }

            &:nth-child(2) {
                >div {
                    width: 80px;
                    height: 80px;
                    background-image: url('../assets/image/kuaiji/biangengO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../assets/image/kuaiji/biangengH.png');
                    }
                }
            }

            &:nth-child(3) {
                >div {
                    width: 80px;
                    height: 80px;
                    background-image: url('../assets/image/kuaiji/yichangO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../assets/image/kuaiji/yichangH.png');
                    }
                }
            }

            &:nth-child(4) {
                >div {
                    width: 80px;
                    height: 80px;
                    background-image: url('../assets/image/kuaiji/jizhangO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../assets/image/kuaiji/jizhangH.png');
                    }
                }
            }

            &:nth-child(5) {
                >div {
                    width: 70px;
                    height: 80px;
                    background-image: url('../assets/image/kuaiji/shenjiO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../assets/image/kuaiji/shenjiH.png');
                    }
                }
            }

            &:nth-child(6) {
                >div {
                    width: 80px;
                    height: 80px;
                    background-image: url('../assets/image/kuaiji/shougouO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../assets/image/kuaiji/shougouH.png');
                    }
                }
            }

            p {
                margin: 5px;
                font-size: 20px;
                font-weight: 600;
            }

            span {
                font-size: 14px;
                color: #B1B1B1;
            }

            &:hover {
                background-color: #EA5514;
                color: #fff;

                >div {
                    background-image: url('../assets/image/kuaiji/zhuceH.png');
                }

                span {
                    color: #fff;
                }
            }
        }
    }

    button {
        margin: 75px 0;
        // margin-left: 46%;
        // margin-left: 45%;
        // min-width: 8%;
        width: 202px;
        height: 56px;
        padding: 5px 15px;
        // font-size: 26px;
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        // background-color: #FE8F3F;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;
        text-align: center;
    }
}

.personal {
    // position: relative;
    padding: 30px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        margin: 30px auto 0;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        width: 1160px;

        li {
            position: relative;
            margin-top: 1vh;
            margin-left: 10px;
            width: 24%;
            height: 340px;
            // background-image: url("../assets/image/personal.png");
            background-size: 100% 100%;
            // border-radius: 0;
            border-radius: 6px;
            overflow: hidden;


            // overflow: hidden;
            >p {
                margin: 0;
                position: absolute;
                bottom: 0;
                padding: 14px;
                width: 89.5%;
                background-color: rgba(0, 0, 0, 0.5);
                text-align: start;
                // border-radius: 0;
                color: #fff;
            }

            .personalT {
                display: block;
                font-size: 18px;
                font-weight: 600;
            }

            .personalC {
                font-size: 16px;
                font-weight: 500;
            }
        }
    }

    button {
        margin-top: 50px;
        margin-bottom: 40px;
        margin-left: 47.5%;
        padding: 2px 8px;
        width: 100px;
        color: #979797;
        border: 1px solid#979797;
        font-size: 18px;
        border-radius: 4px;
        background-color: #F4F4F4;
    }
}

.flow {
    // position: relative;
    padding: 30px 0 100px;
    min-width: 1200px;
    background-image: url("../assets/image/kuaiji/6458486361c124a42770f394d12bef7d526a503611913d-0S20U5_fw1200.png");

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >ul {
        margin: 40px auto;
        // padding: 0 0 20px;
        display: flex;
        flex-wrap: wrap;
        width: 1160px;
        height: 280px;
        background-image: url("../assets/image/kuaiji/line.png");
        background-size: 100% 90%;
        background-repeat: no-repeat;

        li {
            width: 18%;
            text-align: center;
            font-size: 16px;

            p {
                // font-size: 16px;
            }

            span {
                // width: 20px;
                display: block;
                margin: auto;
                width: 150px;
                // font-size: 14px;
                line-height: 25px;
            }

            &:first-child,
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                margin-top: -10px;
            }

            &:last-child,
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9) {
                margin-top: 105px;

                img {
                    transform: rotate(180deg);
                }
            }
        }
    }

}

.advantages {
    // position: relative;
    padding: 50px 0 20px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        display: flex;
        margin: 30px auto;
        width: 1160px;

        // justify-content: space-around;
        img {
            width: 50%;
        }

        ul {
            margin-top: 55px;
            margin-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 50%;
            height: 340px;

            li {
                display: flex;
                margin-top: 20px;
                padding: 8px 15px;
                width: 90%;
                background-color: #fff;
                border-radius: 6px;

                div {
                    width: 30px;
                    height: 30px;
                    background-image: url('../assets/image/kuaiji/quan.png');
                    background-size: 100% 100%;
                    text-align: center;
                    line-height: 30px;
                    color: #fff;
                    font-weight: 600;
                }

                p {
                    margin-left: 20px;
                    line-height: 28px;
                    font-size: 20px;
                }

                &:hover {
                    margin-left: -5%;
                    width: 95%;
                    background-color: #EA5514;
                    color: #fff;

                    div {
                        background-image: url('../assets/image/kuaiji/quanH.png');
                        color: #EA5514;
                    }
                }
            }
        }
    }

    button {
        margin: 75px 0 40px;
        // margin-left: 46%;
        // margin-left: 45%;
        // min-width: 8%;
        width: 202px;
        height: 56px;
        padding: 5px 15px;
        // font-size: 26px;
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        // background-color: #FE8F3F;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;
        text-align: center;
    }
}

.success {
    // position: relative;
    padding: 0px 0 20px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        display: flex;
        justify-content: space-around;
        // align-items: center;
        margin: 60px auto 30px;
        width: 1160px;

        // justify-content: space-around;
        img {
            width: 45%;
            height: 488px;
        }

        ul {
            // flex-grow: 1;
            // margin-top: 110px;
            // display: flex;
            // flex-direction: column;
            // justify-content: space-around;
            // margin-right: 20px;
            width: 53%;
            height: 488px;
            overflow: hidden;

            li {
                position: relative;
                display: flex;
                margin-bottom: 15px;
                padding: 20px 15px;
                // width: 100%;
                height: 23%;
                background-color: #fff;
                border-radius: 6px;

                .img {
                    margin: 0;
                    width: 20px;
                    height: 20px;
                    background-image: url('../assets/image/kuaiji/dui.png');
                    background-size: 100% 100%;
                }

                div {
                    margin-left: 15px;
                    position: relative;
                    text-align: start;
                    cursor: pointer;
                    width: 92%;

                    >p {
                        margin-bottom: 5px;
                        text-align: start;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    span {
                        // width: 100%;
                        font-size: 14px;
                        line-height: 25px;
                        // height: 75px;
                        color: #727272;
                        // display: -webkit-box;
                        // -webkit-box-orient: vertical;
                        // -webkit-line-clamp: 3;
                        // overflow: hidden;
                        // text-overflow: ellipsis;

                    }

                    button {
                        // position: absolute;
                        // top: 0;
                        // left: 150px;
                        margin-left: 40px;
                        padding: 2px 5px;
                        border: 0;
                        border-radius: 2px;
                        background-color: #FCEEE7;
                        color: #EB5E20;
                    }

                }

                .gengduo {
                    position: absolute;
                    right: 20px;
                    width: 20px;
                    height: 4px;
                    background-image: url('../assets/image/gengduo.png');
                    background-size: 100% 100%;
                }

                &:last-child {
                    margin-bottom: 0;

                    &:hover {
                        position: relative;
                        top: -334px;
                        // top: -304px;
                    }
                }

                &:first-child {
                    &:hover {
                        position: relative;
                        top: 0;
                    }
                }

                &:nth-child(2) {
                    &:hover {
                        position: relative;
                        top: -167px;
                    }
                }

                &:hover {
                    height: 448px;
                    background-color: #EA5514;
                    color: #fff;

                    span {
                        color: #fff;
                        display: block !important;
                    }

                    .img {
                        background-image: url('../assets/image/kuaiji/duiW.png');
                    }

                    div {
                        button {
                            background-color: #EF8050;
                            color: #fff;
                        }
                    }

                    .gengduo {
                        display: none;
                    }
                }
            }
        }
    }
}

.offer {
    // position: relative;
    padding: 30px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        display: flex;
        justify-content: space-around;
        margin: 60px auto 40px;
        width: 1160px;

        li {
            // padding: 20px;
            width: 24%;
            background-color: #fff;
            border-radius: 6px;
            text-align: center;

            p {
                margin: auto;
                padding: 40px 40px 20px;
                width: 40%;
                text-align: center;
                border-bottom: 1px dashed #E1E1E1;

                img {
                    // margin: auto;
                    width: 50px;
                    height: 50px;
                }

                span {
                    display: block;
                    margin-top: 10px;
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            button {
                margin: 20px 0;
                padding: 5px 15px;
                color: #fff;
                background-color: #EA5514;
                border-radius: 15px;
                border: 0;
            }

            &:first-child {
                img {
                    width: 60px;
                    height: 60px;
                }

                p {
                    padding-top: 38px;

                    span {
                        margin-top: 5px;
                    }
                }
            }

            &:nth-child(3) {
                img {
                    width: 58px;
                    height: 58px;
                }

                p {
                    padding-top: 35px;

                    span {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

.orange {
    padding: 70px 0;
    width: 100%;
    min-width: 1200px;
    // min-height: 140px;
    background-image: url("../assets/image/橙色背景.png");
    background-size: 100% 100%;
    text-align: center;
    color: #fff;
    font-size: 40px;

    // line-height: 70px;
    span {
        display: block;

        // margin-top: 25px;
        // font-size: 26px;
        &:last-child {
            // font-size: 16px;
        }

        // line-height: 10px;
        img {
            position: relative;
            top: -10px;

            // margin-top: -10px;
            &:last-child {
                transform: rotate(180deg);
            }
        }
    }

    .mini {
        margin-top: 20px;
        font-size: 22px;

        img {
            position: relative;
            top: -4px;

            // margin-top: -10px;
            &:last-child {
                transform: rotate(180deg);
            }
        }
    }
}

.info {
    margin: auto;
    position: relative;
    width: 350px;
    border-radius: 6px;
    text-align: center;
    overflow: hidden;
    background-color: #fff;

    p {
        padding: 20px 40px 40px;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        background-color: #EA5513;
    }

    .name {
        position: absolute;
        top: 60px;
        left: 140px;
        width: 50px;
        height: 50px;
    }

    .cheng {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 25px;
        height: 25px;

    }

    >img {
        &:last-child {
            margin: 40px 0 20px;
            width: 70%;
        }

    }
}

::v-deep .el-dialog--center .el-dialog__body {
    padding-bottom: 0;
}

::v-deep .el-form {
    margin-top: 30px;
    margin-left: -20px;

    .el-form-item {
        .el-form-item__label {
            width: 70px !important;
        }

        .el-form-item__content {
            margin-left: 70px !important;
        }
    }
}

::v-deep .el-textarea__inner {
    height: 150px !important;
}
</style>