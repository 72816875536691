<template>
    <div>
        <top-view :type="type"></top-view>
        <div class="backTop">
            <!-- <div>
                <p>社会责任</p>
                <span class="english">SOCIAL RESPONSIBILITY</span>
                <button @click="gotoAboutus">了解更多</button>
            </div> -->
        </div>
        <div class="gongyi">
            <p><img src="../assets/image/left.png">&nbsp;公益慈善&nbsp;<img src="../assets/image/right.png"></p>
            <span>PUBLIC WELFARE AND CHARITY</span>
            <ul>
                <li v-for="item in dataList" :key="item.dynamicId" @click="gotoContent(item.dynamicId)">
                    <img :src="imgurl + item.dynamicImg">
                    <div>
                        <div style="background-color: #E3F0C8;width: 72px;height: 8px;border-radius: 4px;">
                            <p style="border: 4px solid #8FC31F;width: 30px;border-radius: 4px;"></p>
                        </div>
                        <p>{{ item.dynamicTitle }}</p>
                        <span v-html="item.dynamicContent"></span>
                    </div>
                </li>
                <!-- <li>
                    <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png">
                    <div>
                        <div style="background-color: #E3F0C8;width: 50px;border-radius: 3px;">
                            <p style="border: 3px solid #8FC31F;width: 20px;border-radius: 3px;"></p>
                        </div>
                        <p>北京康行慈善基金会</p>
                        <span>2023年5月唐诚携手北京康行慈善基金会筹备“中欧人才发展基金”成立，开启了中欧职业教育产教融合新篇章。</span>
                    </div>
                </li>
                <li>
                    <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png">
                    <div>
                        <div style="background-color: #E3F0C8;width: 50px;border-radius: 3px;">
                            <p style="border: 3px solid #8FC31F;width: 20px;border-radius: 3px;"></p>
                        </div>
                        <p>北京康行慈善基金会</p>
                        <span>2023年5月唐诚携手北京康行慈善基金会筹备“中欧人才发展基金”成立，开启了中欧职业教育产教融合新篇章。</span>
                    </div>
                </li>
                <li>
                    <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png">
                    <div>
                        <div style="background-color: #E3F0C8;width: 50px;border-radius: 3px;">
                            <p style="border: 3px solid #8FC31F;width: 20px;border-radius: 3px;"></p>
                        </div>
                        <p>北京康行慈善基金会</p>
                        <span>2023年5月唐诚携手北京康行慈善基金会筹备“中欧人才发展基金”成立，开启了中欧职业教育产教融合新篇章。</span>
                    </div>
                </li>
                <li>
                    <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png">
                    <div>
                        <div style="background-color: #E3F0C8;width: 50px;border-radius: 3px;">
                            <p style="border: 3px solid #8FC31F;width: 20px;border-radius: 3px;"></p>
                        </div>
                        <p>北京康行慈善基金会</p>
                        <span>2023年5月唐诚携手北京康行慈善基金会筹备“中欧人才发展基金”成立，开启了中欧职业教育产教融合新篇章。</span>
                    </div>
                </li>
                <li>
                    <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png">
                    <div>
                        <div style="background-color: #E3F0C8;width: 50px;border-radius: 3px;">
                            <p style="border: 3px solid #8FC31F;width: 20px;border-radius: 3px;"></p>
                        </div>
                        <p>北京康行慈善基金会</p>
                        <span>2023年5月唐诚携手北京康行慈善基金会筹备“中欧人才发展基金”成立，开启了中欧职业教育产教融合新篇章。</span>
                    </div>
                </li>
                <li>
                    <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png">
                    <div>
                        <div style="background-color: #E3F0C8;width: 50px;border-radius: 3px;">
                            <p style="border: 3px solid #8FC31F;width: 20px;border-radius: 3px;"></p>
                        </div>
                        <p>北京康行慈善基金会</p>
                        <span>2023年5月唐诚携手北京康行慈善基金会筹备“中欧人才发展基金”成立，开启了中欧职业教育产教融合新篇章。</span>
                    </div>
                </li> -->
            </ul>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>

    </div>
</template>

<script>
import topView from '@/components/topView.vue'
import footerView from '@/components/footerView.vue'
import SideBar from '@/components/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            // imgurl: 'http://192.168.0.199:8087/api',
            imgurl: '',
            dataList: [],
            type:'3'
        }
    },
    created() {
        // this.$store.state.componentKey += 1
        this.getdataList()
        this.website()
        this.imgurl = this.$store.state.imgurl
    },
    methods: {
        gotoAboutus() {
            this.$router.push('/aboutus')
        },
        getdataList() {
            axios.get('/api/pcsystem/TckgDynamic/list', { params: { dynamicType: '6' } }).then((res) => {
                if (res.status == 200) {
                    this.dataList = res.data.rows
                    console.log(this.dataList);
                }
            }).catch()
        },
        // gotoContent(id) {
        //     this.$router.push({ path: '/content', query: { id: id, type: this.type } })
        // },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '唐诚公益' }).then((res) => {
                console.log(res);
            }).catch()
        },
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    // height: 500px;
    // height: 450px;
    background-image: url("../assets/image/gongyiBack.png");
    background-size: 100% 100%;

    // div {
    //     margin: auto;
    //     padding-top: 80px;
    //     width: 1160px;
    //     color: #EA5514;
    //     text-align: center;
    // }

    // p {
    //     margin: 20px 0;
    //     color: #fff;
    //     font-size: 50px;
    //     font-weight: 700;
    // }

    // .english {
    //     display: block;
    //     font-size: 16px;
    //     color: #fff;
    // }

    // button {
    //     margin-top: 30px;
    //     width: 100px;
    //     height: 30px;
    //     line-height: 30px;
    //     color: #fff;
    //     background-color: #FFB258;
    //     border: 0;
    //     border-radius: 15px;
    // }
}


.gongyi {
    // position: relative;
    // margin: auto;
    padding: 60px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        margin: 60px auto 20px;
        width: 1200px;

        li {
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            // align-items: center;
            padding: 30px 20px;
            background-color: #fff;
            border-radius: 6px;

            img {
                // width: 35%;
                // height: 170px;
                width: 387px;
                height: 250px;
                border-top-right-radius: 50px;
                border-bottom-left-radius: 50px;
            }

            >div {
                margin-left: 10px;
                width: 60%;

                >div {
                    margin-top: 40px;
                }

                >p {
                    margin: 20px 0 30px;
                    // font-size: 20px;
                    font-size: 26px;
                    line-height: 37px;
                    font-weight: 600;
                }

                >span {
                    // display: block;
                    width: 90%;
                    // font-size: 14px;
                    font-size: 20px;
                    line-height: 30px;
                    color: #AAAAAA;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &:hover {
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            }

        }
    }
}
</style>