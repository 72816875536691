<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
            <!-- <div>
                <p>企贷事业群</p>
                <span class="english">个性化服务，快速给出解决方案</span>
                <button @click="gotoAboutus">了解更多</button>
            </div> -->
        </div>
        <div class="kuaijiQ">
            <div>
                <p>企贷事业群</p>
                <div
                    style="margin-bottom: 40px;width: 81px;height: 4px;background: linear-gradient( 270deg, #F4F4F4 0%, #EA5514 100%);border-radius: 1px;">
                </div>
                <p class="kuaijiQC">
                    立足于为小微企业及其法人提供融资服务。利用互联网技术数字化手段实现客户触达、数据采集、模型评价等，快速给出解决方案。
                </p>
                <p class="kuaijiQC">
                    让企业能够享受到低息、还款灵活的多样化金融产品，帮助早期企业走出缺乏抵质押资产、难以获得银行融资的困境。
                </p>
                <ul>
                    <li>
                        <img src="../assets/image/qidai/shujvcaiji.png">
                        <span>
                            客户触达
                        </span>
                    </li>
                    <li></li>
                    <li>
                        <img src="../assets/image/qidai/kehuchuda.png">
                        <span>数据采集</span>
                    </li>
                    <li></li>
                    <li>
                        <img src="../assets/image/qidai/moxingpingjia.png">
                        <span>模型评价</span>
                    </li>
                </ul>
            </div>
            <img src="../assets/image/qidai/qidaishiyequn.png">
        </div>
        <div style="padding: 30px 0;background-color: #F4F4F4;min-width: 1200px;">
            <ul class="nbnd">
                <li>
                    <p>01-</p>
                    <div></div>
                    <p>能不能贷</p>
                </li>
                <li>
                    <p>02-</p>
                    <div></div>
                    <p>负债整合</p>
                </li>
                <li>
                    <p>03-</p>
                    <div></div>
                    <p>征信维权</p>
                </li>
                <li>
                    <p>04-</p>
                    <div></div>
                    <p>额度是多少</p>
                </li>
                <li>
                    <p>05-</p>
                    <div></div>
                    <p>贷款利息怎么算</p>
                </li>
            </ul>
        </div>

        <div class="canIdo">
            <p><img src="../assets/image/left.png">&nbsp;我们可以为您的企业做什么&nbsp;<img src="../assets/image/right.png"></p>
            <span>WHAT CAN WE DO FOR YOUR BUSINESS</span>
            <ul>
                <li>
                    <img src="../assets/image/qidai/jiangben.png">
                    <p>降本增效</p>
                </li>
                <li>
                    <img src="../assets/image/qidai/youhuaxindai.png">
                    <p>优化信贷结构</p>
                </li>
                <li>
                    <img src="../assets/image/qidai/pojie.png">
                    <p>破解融资难题</p>
                </li>
            </ul>>
        </div>
        <div class="service">
            <p><img src="../assets/image/left.png">&nbsp;服务内容&nbsp;<img src="../assets/image/right.png"></p>
            <span>SERVICE CONTENT</span>
            <ul>
                <li>

                    <p class="qizhi">01</p>
                    <span>
                        <p>企业信用贷</p>
                        <span>专业指导</span>
                    </span>
                    <div></div>
                    <!-- <img src="../assets/image/qidai/bottomLine.png" class="bottom"> -->
                </li>
                <li>

                    <p class="qizhi">02</p>
                    <!-- <img src="../assets/image/kuaiji/biangengO.png"> -->
                    <span>
                        <p>抵押消费贷</p>
                        <span>利息低，到账快</span>
                    </span>
                    <div></div>
                </li>
                <li>

                    <p class="qizhi">03</p>
                    <!-- <img src="../assets/image/kuaiji/yichangO.png"> -->
                    <span>
                        <p>企业税金贷</p>
                        <span>缓解公司的燃眉之急</span>
                    </span>
                    <div></div>
                </li>
                <li>

                    <p class="qizhi">04</p>
                    <!-- <img src="../assets/image/kuaiji/jizhangO.png"> -->
                    <span>
                        <p>抵押经营贷</p>
                        <span>正规渠道，系统快批</span>
                    </span>
                    <div></div>
                </li>
                <li>

                    <p class="qizhi">05</p>
                    <!-- <img src="../assets/image/kuaiji/shenjiO.png"> -->
                    <span>
                        <p>资金证明</p>
                        <span>满足企业各类需求</span>
                    </span>
                    <div></div>
                </li>
                <li>

                    <p class="qizhi">06</p>
                    <!-- <img src="../assets/image/kuaiji/shougouO.png"> -->
                    <span>
                        <p>个人信用贷</p>
                        <span>流程简单，额度高</span>
                    </span>
                    <div></div>
                </li>
            </ul>
            <div style="display: flex;justify-content: center;"><button @click="infoShow = true">点击咨询</button></div>
        </div>
        <div class="orange">
            <img src="../assets/image/kuaiji/whiteLine.png">&nbsp;&nbsp;熟知利率政策，匹配最优方案，面面俱到&nbsp;&nbsp;<img
                src="../assets/image/kuaiji/whiteLine.png">
            <span>您只需提供相关资料即可</span>
        </div>
        <div class="personal">
            <p><img src="../assets/image/left.png">&nbsp;资深专家&nbsp;<img src="../assets/image/right.png"></p>
            <span>SENIOR EXPERTS</span>
            <ul>
                <li v-for="item in personalList" :key="item.personnelId">
                    <img :src="imgurl + item.personnelImg" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">{{ item.personnelName }}</span>
                        <span class="personalC">{{ item.personnelPosition }}</span>
                    </p>
                </li>
                <!-- <li>
                    <img src="../assets/image/qidai/personal10.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">杜少鹏</span>
                        <span class="personalC">唐诚资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/qidai/personal16.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">代振坤</span>
                        <span class="personalC">唐诚石家庄资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/qidai/personal15.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">沈杰</span>
                        <span class="personalC">唐诚北京资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/qidai/personal9.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">张娟竺</span>
                        <span class="personalC">唐诚广州资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/qidai/personal11.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">郑姣姣</span>
                        <span class="personalC">唐诚天津资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/qidai/personal12.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">孔候平</span>
                        <span class="personalC">唐诚太远资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/qidai/personal15.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">东倩</span>
                        <span class="personalC">唐诚廊坊资质事业部总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/qidai/personal14.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">赵晓东</span>
                        <span class="personalC">唐诚石家庄资质事业部高级顾问</span>
                    </p>
                </li> -->
            </ul>
        </div>
        <div class="flow">
            <p><img src="../assets/image/left.png">&nbsp;服务流程&nbsp;<img src="../assets/image/right.png"></p>
            <span>SERVICE FLOW</span>
            <ul>
                <li>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <p>01.</p>
                    <span>个人信用贷</span>

                </li>
                <li>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <p>02.</p>
                    <span>人员审核</span>
                </li>
                <li>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <p>03.</p>
                    <span>匹配金融机构</span>
                </li>
                <li>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <p>04.</p>
                    <span>线下协调面签</span>
                </li>
                <li>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <p>05.</p>
                    <span>机构放款</span>

                </li>
                <li>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <p>06.</p>
                    <span>企业运转</span>
                </li>
            </ul>
        </div>
        <div class="advantages">
            <p><img src="../assets/image/left.png">&nbsp;服务优势&nbsp;<img src="../assets/image/right.png"></p>
            <span>SERVICE ADVANTAGES</span>
            <div>
                <img src="../assets/image/qidai/fuwuyoushi.png">
                <ul>
                    <li>
                        <div>01</div>
                        <p>专业分析，精准匹配贷款项目</p>
                    </li>
                    <li>
                        <div>02</div>
                        <p>个性定制，实现财务规划最优化</p>
                    </li>
                    <li>
                        <div>03</div>
                        <p>50+战略联盟金融机构，满足多样化需求</p>
                    </li>
                    <li>
                        <div>04</div>
                        <p>服务人员稳定，保障服务的连续性</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="success">
            <p><img src="../assets/image/left.png">&nbsp;成功案例&nbsp;<img src="../assets/image/right.png"></p>
            <span>SUCCESS STORIES</span>
            <ul>
                <li v-for="item in caseList" :key="item.caseId" @mouseenter="changeContent(item)"
                    @mouseleave="recoveryContent(item)">
                    <img :src="imgurl + item.caseImg">
                    <div>
                        <div class="gengduo"></div>
                        <p>项目：{{ item.caseTitle }}</p>
                        <button>耗时：{{ item.caseHs }}</button>
                        <span v-html="item.caseTitle2"></span>
                    </div>
                </li>
                <!-- <li>
                    <img src="../assets/image/qidai/4a93911dec3267039bfa4b6d8fd1d3e6(1).png">
                    <div>
                        <p>项目：财务外包111</p>
                        <button>耗时：10天</button>
                        <span>客户在寻求银行贷款时，遇到了一个难题：银行要求提供《项目可行性研究报告》。由于这项任务的专业性很强，客户选择了唐诚来完成。在撰写过程中，顾问团队帮助客户解决了许多细节问题，并在项目总投资发生变化时，迅速进行了更正和改版。最终，为客户提交了一份高质量的《项目可行性研究报告》，并一次性通过了银行的审核，无需任何修改。

                            客户对唐诚公司的专业能力和高效服务给予了高度评价，认为能够撰写出如此高质量报告的服务机构并不多见。这次合作不仅解决了燃眉之急，还进一步加深了双方的合作关系，客户给出了满分的优秀评价。</span>

                    </div>
                </li>
                <li>
                    <img src="../assets/image/qidai/4a93911dec3267039bfa4b6d8fd1d3e6(2).png">
                    <div>
                        <p>项目：财务外包222</p>
                        <button>耗时：10天</button>
                        <span>客户在寻求银行贷款时，遇到了一个难题：银行要求提供《项目可行性研究报告》。由于这项任务的专业性很强，客户选择了唐诚来完成。在撰写过程中，顾问团队帮助客户解决了许多细节问题，并在项目总投资发生变化时，迅速进行了更正和改版。最终，为客户提交了一份高质量的《项目可行性研究报告》，并一次性通过了银行的审核，无需任何修改。

                            客户对唐诚公司的专业能力和高效服务给予了高度评价，认为能够撰写出如此高质量报告的服务机构并不多见。这次合作不仅解决了燃眉之急，还进一步加深了双方的合作关系，客户给出了满分的优秀评价。</span>

                    </div>
                </li> -->
            </ul>
            <div style="display: flex;justify-content: center;" class="btn"><button
                    @click="infoShow = true">点击咨询</button></div>
            <!-- <button @click="infoShow = true">点击咨询</button> -->
        </div>
        <div class="offer">
            <p><img src="../assets/image/left.png">&nbsp;我们还提供这些服务&nbsp;<img src="../assets/image/right.png"></p>
            <span>WE ALSO OFFER THESE SERVICES</span>
            <ul>
                <li>
                    <p>
                        <img src="../assets/image/kuaiji/jiangpai.png">
                        <span>建筑资质</span>
                    </p>
                    <button @click="gotoZizhi">了解更多</button>
                </li>
                <li>
                    <p>
                        <img src="../assets/image/kuaiji/caishui.png">
                        <span>财税服务</span>
                    </p>
                    <button @click="gotoKuaiji">了解更多</button>
                </li>
                <li>
                    <p>
                        <img src="../assets/image/kuaiji/haiwai.png">
                        <span>海外业务</span>
                    </p>
                    <button @click="gotoHaiwai">了解更多</button>
                </li>
                <li>
                    <p>
                        <img src="../assets/image/kuaiji/zhishi.png">
                        <span>知识产权</span>
                    </p>
                    <button @click="gotoZonghe">了解更多</button>
                </li>
            </ul>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>
        <!-- <div v-if="infoShow"
            style="padding-top: 200px;width:100vw;height: 100vh;background-color:rgba(0, 0, 0, 0.4);position: fixed;top: 0">
            <div class="info">
                <p>扫码关注微信公众号</p>
                <img src="../assets/icon/cheng.png" class="cheng" @click="infoShow = false">
                <img src="../assets/image/name.png" class="name">
                <img src="../assets/image/erweima.png">
            </div>
        </div> -->
        <el-dialog title="请填写您的个人信息" :visible.sync="infoShow" width="30%" center>
            <span
                style="font-size: 12px;color: #A5A5A5;text-align: center;display: block;margin-top: -30px;">我们会在30分钟内联系您</span>

            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入您的姓名"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="tel">
                    <el-input v-model="ruleForm.tel" placeholder="请输入您的电话号码"></el-input>
                </el-form-item>
                <el-form-item label="留言" prop="content">
                    <el-input v-model="ruleForm.content" type="textarea" placeholder="请输入您的留言（不超过300字）"
                        class="inputC"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm('ruleForm')">立即提交</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import topView from '@/components/topView.vue'
import footerView from '@/components/footerView.vue'
import SideBar from '@/components/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            personalList: [],
            params: { pageSize: 8, pageNum: 1, personnelType: "2" },
            // imgurl: 'http://192.168.0.199:8087/api',
            imgurl: '',
            caseList: [],
            ruleForm: {
                name: '',
                tel: '',
                content: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' },
                    // { min: 7, max: 11, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                tel: [
                    { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                content: [
                    // { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { min: 0, max: 300, message: '最多输入300字', trigger: 'blur' }
                ]

            },
            text: '',
        }
    },
    created() {
        this.getPersonalList()
        this.getcaseList()
        this.website()
        this.imgurl = this.$store.state.imgurl
        // this.$store.state.componentKey += 1
    },
    methods: {
        getPersonalList() {
            axios.get('/api/pcsystem/TckgPersonnel/list', { params: this.params }).then((res) => {
                if (res.status == 200) {
                    this.personalList = res.data.rows
                    console.log(this.personalList);
                }

            }).catch()
        },
        getcaseList() {
            axios.get('/api/pcsystem/TckgCase/list', { params: { pageNum: 1, pageSize: 3, caseType: '2' } }).then((res) => {
                if (res.status == 200) {
                    this.caseList = res.data.rows
                    console.log(this.caseList);
                }
            }).catch()
        },
        gotoAboutus() {
            this.$router.push('/aboutus')
        },
        gotoZonghe() {
            this.$router.push({ path: '/zonghe', query: { key: '2' } })
        },
        gotoHaiwai() {
            this.$router.push({ path: '/haiwai', query: { key: '3' } })
        },
        gotoZizhi() {
            this.$router.push({ path: '/zizhi', query: { key: '2' } })
        },
        gotoKuaiji() {
            this.$router.push({ path: '/kuaiji', query: { key: '2' } })
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '企贷事业群' }).then((res) => {
                console.log(res);
            }).catch()
        },
        //提交信息
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    this.infoShow = false
                    console.log(this.ruleForm);
                    axios.post('/api/pcsystem/TckgConsult', { params: this.ruleForm }).then((res) => {
                        // console.log(res);
                        if (res.status == 200) {
                            // console.log(111111);
                            this.$message({
                                message: '提交成功，请耐心等待',
                                type: 'success'
                            });
                        }
                    }).catch()
                } else {
                    // this.$message({
                    //     message: '提交失败，请稍后重试',
                    //     type: 'warning'
                    // });
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        changeContent(item) {
            this.text = item.caseTitle2
            item.caseTitle2 = item.caseContent
            // console.log(item);
        },
        recoveryContent(item) {
            console.log(this.text);
            item.caseTitle2 = this.text
            // console.log(item);
        }
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    // height: 500px;
    // height: 450px;
    background-image: url("../assets/image/qidaiBack.png");
    background-size: 100% 100%;

    // div {
    //     margin: auto;
    //     padding-top: 80px;
    //     width: 1160px;
    //     color: #EA5514;
    //     text-align: center;
    // }

    // p {
    //     margin: 20px 0;
    //     color: #fff;
    //     font-size: 50px;
    //     font-weight: 700;
    // }

    // .english {
    //     display: block;
    //     font-size: 16px;
    //     color: #fff;
    // }

    // button {
    //     margin-top: 30px;
    //     width: 100px;
    //     height: 30px;
    //     line-height: 30px;
    //     color: #fff;
    //     background-color: #FFB258;
    //     border: 0;
    //     border-radius: 15px;
    // }
}

.kuaijiQ {
    margin: 60px auto;
    display: flex;
    justify-content: space-around;
    width: 1160px;

    img {
        border-radius: 0 !important;
    }

    div {
        margin-right: 20px;

        >p {
            margin-bottom: 10px;
            font-size: 22px;
            font-weight: 600;
        }

        .kuaijiQC {
            margin: 20px 0;
            font-size: 18px;
            line-height: 25px;
            font-weight: 400;
        }

        ul {
            margin-top: 50px;
            display: flex;
            justify-content: space-evenly;
            box-shadow: 0 0 10px 0 #F4F4F4;

            li {
                padding: 20px 30px;
                text-align: center;

                // border-right: 1px solid black;
                // &:last-child{
                //     border: 0;
                // }
                &:nth-child(2),
                &:nth-child(4) {
                    margin-top: 35px;
                    padding: 0;
                    width: 1px;
                    height: 26px;
                    // border: 1px solid #C8C4C4;
                    background-color: #C8C4C4;
                }

                span {
                    display: block;
                    font-size: 14px;
                    color: #949494;
                }
            }
        }
    }

}

.nbnd {
    display: flex;
    justify-content: space-around;
    margin: 30px auto;
    width: 1160px;

    li {
        width: 19%;
        background-color: #fff;
        border-radius: 6px;
        text-align: center;

        p {
            margin: 20px 0;
            font-size: 20px;
            font-weight: 600;
        }

        div {
            margin: auto;
            width: 50px;
            height: 50px;
            background-image: url('../assets/image/qidai/nbndO.png');
            background-size: 100% 100%;
        }

        &:first-child {
            border-top-left-radius: 100px;
        }

        &:nth-child(2) {
            div {
                background-image: url('../assets/image/qidai/fuzhaizhengheO.png');
            }

            &:hover {
                div {
                    background-image: url('../assets/image/qidai/fuzhaizhengheH.png');
                }
            }
        }

        &:nth-child(3) {
            div {
                width: 45px;
                height: 50px;
                background-image: url('../assets/image/qidai/zhengxinweiquanO.png');
            }

            &:hover {
                div {
                    background-image: url('../assets/image/qidai/zhengxinweiquanH.png');
                }
            }
        }

        &:nth-child(4) {
            div {
                width: 40px;
                height: 50px;
                background-image: url('../assets/image/qidai/edushiduoshaoO.png');
            }

            &:hover {
                div {
                    background-image: url('../assets/image/qidai/edushiduoshaoH.png');
                }
            }
        }

        &:nth-child(5) {
            border-top-right-radius: 100px;

            div {
                width: 45px;
                height: 50px;
                background-image: url('../assets/image/qidai/daikuanlixizenmesuanO.png');
            }

            &:hover {
                div {
                    background-image: url('../assets/image/qidai/daikuanlixizenmesuanH.png');
                }
            }
        }

        &:hover {
            background-color: #EA5514;
            color: #fff;

            div {
                background-image: url('../assets/image/qidai/nbndH.png');
            }
        }
    }
}

.canIdo {
    // position: relative;
    padding: 30px 0;
    min-width: 1200px;
    // background-color: #F4F4F4;
    background-image: url('../assets/image/qidai/60f36944c6bf951d8579d623d7e627ac62513e922dccf6-6Npbt3_fw1200.png');

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        display: flex;
        justify-content: space-around;
        margin: 80px auto 30px;
        width: 1160px;

        li {
            width: 30%;
            text-align: center;

            img {
                width: 100px;
                height: 100px;
            }

            p {
                margin-top: 20px;
                font-size: 20px;
                font-weight: 600;
            }
        }
    }

}

.service {
    // position: relative;
    padding: 75px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 50px auto 20px;
        width: 1160px;

        li {
            position: relative;
            margin-bottom: 1%;
            padding: 50px 20px 30px;
            // display: flex;
            // flex-direction: column;
            align-items: center;
            width: 29%;
            background-color: #fff;
            border-radius: 6px;
            cursor: pointer;
            // z-index: 9999;

            >div {
                position: absolute;
                top: 60px;
                right: 20px;
                // margin: 30px 0 20px;
                // margin-left: 100px;
                width: 50px;
                height: 50px;
                background-image: url('../assets/image/qidai/qiyexinyongd.png');
                background-size: 100% 100%;
            }

            &:nth-child(2) {
                >div {
                    background-image: url('../assets/image/qidai/xiaofeid.png');
                }
            }

            &:nth-child(3) {
                >div {
                    background-image: url('../assets/image/qidai/shuijind.png');
                }
            }

            &:nth-child(4) {
                >div {
                    background-image: url('../assets/image/qidai/jingyingD.png');
                }
            }

            &:nth-child(5) {
                >div {
                    background-image: url('../assets/image/qidai/zijinzhengming.png');
                }
            }

            &:nth-child(6) {
                >div {
                    background-image: url('../assets/image/qidai/gerenxinyongd.png');
                }
            }

            >span {
                // margin-top: 50px;
                display: block;

                p {
                    margin: 10px 0;
                    font-size: 20px;
                    font-weight: 600;
                }

                span {
                    font-size: 14px;
                    color: #B1B1B1;
                }
            }

            .bottom {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                // z-index: 0;
            }

            &:hover {
                background-image: url('../assets/image/qidai/bottomLine.png');
                background-size: 100% 10%;
                background-repeat: no-repeat;
                background-position: 0 100%;
            }

            .qizhi {
                position: absolute;
                top: -10px;
                left: 20px;
                margin: 10px 0;
                width: 35px;
                height: 50px;
                color: #F2956C;
                text-align: center;
                line-height: 40px;
                background-image: url('../assets/image/zizhi/qizhi.png');
                background-size: 100% 100%;
            }
        }
    }

    button {
        margin: 60px 0 10px;
        // margin-left: 46%;
        // margin-left: 45%;
        // min-width: 8%;
        width: 202px;
        height: 56px;
        padding: 5px 15px;
        // font-size: 26px;
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        // background-color: #FE8F3F;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;
        text-align: center;
    }

}

.personal {
    // position: relative;
    padding: 60px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        margin: 40px auto 0;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        width: 1160px;

        li {
            position: relative;
            margin-top: 1vh;
            margin-left: 10px;
            width: 24%;
            height: 340px;
            // background-image: url("../assets/image/personal.png");
            background-size: 100% 100%;
            // border-radius: 0;
            border-radius: 6px;
            overflow: hidden;

            // overflow: hidden;
            >p {
                margin: 0;
                position: absolute;
                bottom: 0;
                padding: 14px;
                width: 89.5%;
                background-color: rgba(0, 0, 0, 0.5);
                text-align: start;
                // border-radius: 0;
                color: #fff;
            }

            .personalT {
                display: block;
                font-size: 18px;
                font-weight: 600;
            }

            .personalC {
                font-size: 16px;
                font-weight: 500;
            }
        }
    }

    button {
        margin-top: 20px;
        margin-left: 48%;
        padding: 2px 8px;
        color: #9B9B9B;
        border: 1px solid#9B9B9B;
    }
}

.flow {
    // position: relative;
    padding: 50px 0;
    min-width: 1200px;
    background-image: url("../assets/image/kuaiji/6458486361c124a42770f394d12bef7d526a503611913d-0S20U5_fw1200.png");

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >ul {
        margin: 170px auto 20px;
        // padding: 0 0 20px;
        display: flex;
        // flex-wrap: wrap;
        width: 1160px;
        height: 200px;
        background-image: url("../assets/image/qidai/line.png");
        background-size: 100% 50%;
        background-repeat: no-repeat;

        li {
            width: 150px;
            height: 100px;
            // text-align: center;
            color: #fff;

            p {
                margin-top: 15px;
                margin-left: 20px;
                font-size: 20px;
                text-align: start;
            }

            span {
                display: block;
                margin: 5px 20px 10px;
                font-size: 16px;
            }

            &:nth-child(odd) {
                margin-top: -100px;
                margin-left: 50px;
                background-image: url('../assets/image/zizhi/duihuaKT.png');
                background-size: 100% 100%;


            }

            &:nth-child(even) {
                margin-top: 100px;
                // margin-left: 40px;
                background-image: url('../assets/image/qidai/duihuaKB.png');
                background-size: 100% 100%;

                p {
                    margin-top: 30px;
                    // margin-left: 20px;
                    // font-size: 20px;
                    // text-align: start;
                }

                // span {
                //     display: block;
                //     margin: 35px 20px 10px;
                //     font-size: 14px;
                // }
            }

            &:nth-child(2) {
                margin-top: 50px;
                margin-left: 15px;
            }

            &:nth-child(3) {
                margin-top: -50px;
                margin-left: 60px;
            }

            &:nth-child(4) {
                margin-left: 15px;
            }

            &:nth-child(5) {
                margin-top: -50px;
                margin-left: 50px;
            }

            &:nth-child(6) {
                margin-top: 60px;
                margin-left: 25px;
            }
        }
    }
}

.advantages {
    // position: relative;
    padding: 50px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        display: flex;
        margin: 60px auto 10px;
        width: 1160px;

        // justify-content: space-around;
        img {
            width: 50%;
        }

        ul {
            margin-top: 30px;
            margin-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 50%;
            height: 340px;

            li {
                display: flex;
                // margin-top: 20px;
                padding: 8px 15px;
                width: 90%;
                background-color: #fff;
                border-radius: 6px;

                div {
                    width: 30px;
                    height: 30px;
                    background-image: url('../assets/image/kuaiji/quan.png');
                    background-size: 100% 100%;
                    text-align: center;
                    line-height: 30px;
                    color: #fff;
                    font-weight: 600;
                }

                p {
                    margin-left: 20px;
                    line-height: 30px;
                    font-size: 20px;
                }

                &:hover {
                    margin-left: -5%;
                    width: 95%;
                    background-color: #EA5514;
                    color: #fff;

                    div {
                        background-image: url('../assets/image/kuaiji/quanH.png');
                        color: #EA5514;
                    }
                }
            }
        }
    }

    button {
        margin-left: 48%;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        background-color: #FE8F3F;
        border: 0;
        border-radius: 20px;
    }
}

.success {
    // position: relative;
    padding: 30px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 60px auto 30px;
        width: 1160px;
        // height: 417px;
        // height: 392px;
        height: 400px;
        overflow: hidden;
        // width: 50%;

        >li {
            // display: flex;
            // margin-bottom: 25px;
            padding: 20px 15px;
            width: 30%;
            height: 360px;
            // height: 417px;
            background-color: #fff;
            border-radius: 6px;

            >img {
                width: 100%;
                height: 200px;
            }

            >div {
                position: relative;
                margin-left: 10px;
                // position: relative;
                text-align: start;

                // width: 500px;
                >p {
                    margin-top: 10px;
                    text-align: start;
                    font-size: 16px;
                    font-weight: 600;
                }

                >span {
                    display: block;
                    margin-top: 10px;
                    font-size: 14px;
                    line-height: 25px;
                    color: #AFAFAF;
                    // display: -webkit-box;
                    // -webkit-box-orient: vertical;
                    // -webkit-line-clamp: 3;
                    // overflow: hidden;
                    // text-overflow: ellipsis;

                }

                button {
                    margin-top: 5px;
                    padding: 2px 5px;
                    border: 0;
                    border-radius: 2px;
                    background-color: #E6F2CC;
                    color: #A4C361;
                }

                .gengduo {
                    position: absolute;
                    right: 0px;
                    top: 10px;
                    width: 20px;
                    height: 4px;
                    background-image: url('../assets/image/gengduo.png');
                    background-size: 100% 100%;
                }
            }

            &:hover {
                width: 100%;
                height: 360px;
                color: #fff;
                background-color: #E65413;

                img {
                    height: 0;
                }

                div {
                    padding: 0 20px 0 0;

                    >p {
                        margin: 20px 0 0px;
                        font-size: 18px;
                    }

                    >span {
                        color: #fff;
                        display: block;
                        line-height: 24px;
                    }

                    button {
                        margin: 20px 0;
                        color: #fff;
                        background-color: #EE824E;
                    }
                }

                .gengduo {
                    display: none;
                }
            }

            &:nth-child(2) {
                &:hover {
                    position: relative;
                    top: -400px;
                }
            }

            &:last-child {
                &:hover {
                    position: relative;
                    top: -400px;
                }
            }
        }
    }

    .btn {
        >button {
            margin: 30px 0 35px;
            // margin-left: 46%;
            // margin-left: 45%;
            // min-width: 8%;
            width: 202px;
            height: 56px;
            padding: 5px 15px;
            // font-size: 26px;
            font-size: 26px;
            font-weight: 500;
            color: #fff;
            // background-color: #FE8F3F;
            background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
            border: 0;
            border-radius: 30px;
            text-align: center;
        }
    }


}

.offer {
    // position: relative;
    padding: 0px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        display: flex;
        justify-content: space-around;
        margin: 60px auto 40px;
        width: 1160px;

        li {
            // padding: 20px;
            width: 24%;
            background-color: #fff;
            border-radius: 6px;
            text-align: center;

            p {
                margin: auto;
                padding: 40px 40px 20px;
                width: 40%;
                text-align: center;
                border-bottom: 1px dashed #E1E1E1;

                img {
                    // margin: auto;
                    width: 50px;
                    height: 50px;
                }

                span {
                    display: block;
                    margin-top: 10px;
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            button {
                margin: 20px 0;
                padding: 5px 15px;
                color: #fff;
                background-color: #EA5514;
                border-radius: 15px;
                border: 0;
            }

            &:first-child {
                img {
                    width: 60px;
                    height: 60px;
                }

                p {
                    padding-top: 38px;

                    span {
                        margin-top: 5px;
                    }
                }
            }

            &:nth-child(2) {
                img {
                    width: 70px;
                    height: 65px;
                }

                p {
                    padding-top: 33px;

                    span {
                        margin-top: 5px;
                    }
                }
            }

            &:nth-child(3) {
                img {
                    width: 60px;
                    height: 60px;
                }

                p {
                    padding-top: 38px;

                    span {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

.orange {
    padding: 70px 0;
    min-width: 1200px;
    width: 100%;
    // height: 140px;
    background-image: url("../assets/image/橙色背景.png");
    background-size: 100% 100%;
    text-align: center;
    color: #fff;
    font-size: 40px;

    img {
        position: relative;
        top: -10px;

        &:nth-child(2) {
            transform: rotate(180deg) !important;
        }
    }

    // line-height: 150px;
    span {
        display: block;
        margin-top: 20px;
        font-size: 22px;

        // line-height: 10px;

    }
}

.info {
    margin: auto;
    position: relative;
    width: 350px;
    border-radius: 6px;
    text-align: center;
    overflow: hidden;
    background-color: #fff;

    p {
        padding: 20px 40px 40px;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        background-color: #EA5513;
    }

    .name {
        position: absolute;
        top: 60px;
        left: 140px;
        width: 50px;
        height: 50px;
    }

    .cheng {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 25px;
        height: 25px;

    }

    >img {
        &:last-child {
            margin: 40px 0 20px;
            width: 70%;
        }

    }
}

::v-deep .el-dialog--center .el-dialog__body {
    padding-bottom: 0;
}

::v-deep .el-form {
    margin-top: 30px;
    margin-left: -20px;

    .el-form-item {
        .el-form-item__label {
            width: 70px !important;
        }

        .el-form-item__content {
            margin-left: 70px !important;
        }
    }
}

::v-deep .el-textarea__inner {
    height: 150px !important;
}
</style>